import CreateIcon from "@mui/icons-material/Create";
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
// import homepageVideo from "../../assets/videos/homepageVideo.mp4";
import homepageVideo from "../../assets/videos/landingVideo.mp4";
import LandingHeader from "../../layout/header";
import ForgotPassword from "../Admin/ForgotPassword";
import Login from "../authentication/login";
import Signup from "../authentication/sign up";
import "./landing.css";
import { ThumbUp } from "@mui/icons-material";
import ListSlider from "./ListSlider";
import hero_bg from "../../assets/images/NewImages/hero_bg.svg";

const RootStyle = styled(Box)({
  background: "#FFF",
  padddingTop: "16px",
});
const VideoBox = styled(Box)(({ theme }) => ({
  // padding: "0px 24px",
  width: "100%",
  background: "#FFFFFF",
  borderRadius: "16px",
  boxShadow: "0px 0px 24.35796356201172px 0px rgba(0, 0, 0, 0.08)",
  backdropFilter: "blur(12.17898178100586px)",
  border: "12px solid rgba(0, 0, 0, 0.12)",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "Righteous",
  fontWeight: 800,
  color: "#353535",
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    fontSize: "30px",
    textAlign: "center",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "40px",
  },
}));
const SubTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Righteous",
  fontWeight: 400,
  color: "#353535",
  textAlign: "center",
  fontSize: "34px",
  [theme.breakpoints.down("md")]: {
    fontSize: "32px",
    textAlign: "center",
  },
  // [theme.breakpoints.down("sm")]: {
  //   fontSize: "18px",
  // },
}));

const BtnStart = styled(Button)({
  background: "#353535 !important",
  color: "#FFFFFF",
  borderRadius: "4px",
  fontWeight: 600,
  textTransform: "none",
  fontFamily: "Raleway",
  fontSize: "16px",
  paddingRight: "21px",
  paddingLeft: "21px",
});
const BtnPowered = styled(Button)({
  background: "rgba(53, 53, 53, 0.06) !important",
  borderRadius: "4px",
  color: "#353535",
  fontWeight: 600,
  textTransform: "none",
  fontFamily: "Raleway",
  fontSize: "16px",
  paddingRight: "21px",
  paddingLeft: "21px",
  "&:hover": {
    background: "#F3F3F3",
    backdropFilter: "blur(1.5px)",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.20)",
  },
});

const HomeSection = ({ onSuccess }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const videoUrl =
    // "https://veracious-documents.s3.us-west-2.amazonaws.com/LandingVideo.webp";
    "https://veracious-documents.s3.us-west-2.amazonaws.com/videos/LandingVideo.webp";
  const medium = useMediaQuery("(max-width:1024px)");
  const location = useLocation();

  const [state, setState] = useState({
    loginModal: location.state ? true : false,
    forgotModal: false,
    signupModal: false,
  });

  const toggle = (name) => {
    if (name === "faqs" || name === "pricing" || name === "features") {
      onSuccess(name);
    } else if (name === "forgotModal" || name === "signupModal") {
      setState((prev) => ({ ...prev, [name]: !prev[name], loginModal: false }));
    } else {
      setState((prev) => ({ ...prev, [name]: !prev[name] }));
    }
  };

  return (
    <RootStyle
      sx={{
        backgroundColor: "transparent !important",
        mt: { xs: 6, md: 6 },
        mb: 6,
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        spacing={6}
        height={"fit-content"}
      >
        <Box width={isMobile ? "80%" : "40%"}>
          <BtnStart
            variant="contained"
            fullWidth
            // onClick={() => toggle("loginModal")}
            sx={{
              backgroundColor: "#fff !important",
              color: "rgba(20, 34, 75, 1) !important",
              boxShadow: "none",

              "&:hover": {
                backgroundColor: "#fff !important",
                color: "rgba(20, 34, 75, 1) !important",
                boxShadow: "none",
              },
            }}
            startIcon={
              <ThumbUp
                sx={{
                  color: "#F2C94C",
                }}
              />
            }
          >
            Best AI Tool For Research
          </BtnStart>
        </Box>
        <Stack
          direction="row"
          spacing={1}
          width={"100%"}
          justifyContent={"center"}
        >
          <div className="circles" style={{ position: "absolute" }}>
            <div className="circle1"></div>
            <div className="circle2"></div>
            <div className="circle3"></div>
            <div className="circle4"></div>
          </div>
          <SubTitle variant="h4" component="div" textAlign={"center"}>
            <Typography variant="span" sx={{ color: "#4A90E2" }}>
              Revolutionize Your{" "}
            </Typography>
            {!isMobile ? (
              <Typography variant="span" sx={{ color: "#1AB394" }}>
                Research Workflow
              </Typography>
            ) : (
              <Typography
                sx={{
                  color: "#1AB394",
                  fontFamily: "Righteous",
                  fontSize: "32px",
                }}
              >
                Research Workflow
              </Typography>
            )}
          </SubTitle>
        </Stack>
        <Box sx={{ width: { xs: "90%", sm: "80%", md: "60%" } }}>
          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "Raleway",
              color: "#14224B",
            }}
          >
            Experience unparalleled efficiency and precision in your research
            endeavors with ResearchPal's AI-driven solutions. From Literature
            Review, Paper Insights, Search to Reference Management and much
            more, elevate your work and productivity with the best AI tool for
            research writing.
          </Typography>
        </Box>
        <Box width={isMobile ? "80%" : "40%"}>
          <BtnStart
            variant="contained"
            onClick={() => toggle("loginModal")}
            fullWidth
            sx={{
              //i want linear gradient here but it is not working
              background:
                "linear-gradient(90deg, rgba(86, 113, 241, 1) 0%, rgba(23, 206, 173, 1) 100%) !important",
              "&:hover": {
                background:
                  "linear-gradient(90deg, rgba(48, 70, 174, 1) 0%, rgba(20, 181, 153, 1) 100%) !important",
                boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.20)",
              },
            }}
          >
            Start Writing
            <CreateIcon fontSize="small" sx={{ ml: 1 }} />
          </BtnStart>
        </Box>
        <VideoBox sx={{ width: "80%" }}>
          <div
            style={{
              clipPath: "inset(0 0 0.2% 0.2%)",
              overflow: "hidden",
              borderRadius: "6px",
            }}
          >
            <video
              src={homepageVideo}
              type="video/mp4"
              autoPlay
              loop
              muted
              style={{ width: "100%" }}
            >
              Your browser does not support the video.
            </video>
          </div>

          {/* <img
            src={videoUrl}
            alt=""
            style={{ clipPath: "inset(0 0 0 0.1%)" }}
          /> */}
        </VideoBox>

        <Box width={"100%"}>
          <ListSlider />
        </Box>
      </Stack>
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 15,
          px: { xs: 0, md: 5.6 },
        }}
      >
        <Grid
        container
        spacing={2}
        sx={{ mt: 5, zIndex: 0, px: { xs: 0, md: 5.6 } }}
      >
        <Grid
          md={6}
          xs={12}
          sx={{
            minHeight: "85vh",
            display: "flex",
            alignItems: "center",
            justifyContent: { xs: "center" },
          }}
        >
        <Stack alignItems="center" justifyContent="center" width={"100%"}>
          <BtnStart
            variant="contained"
            // onClick={() => toggle("loginModal")}
          >
            Best AI Tool For Research
            <CreateIcon fontSize="small" sx={{ ml: 1 }} />
          </BtnStart>
        </Stack>
        <div className="circles" style={{ position: "absolute" }}>
            <div className="circle1"></div>
            <div className="circle2"></div>
            <div className="circle3"></div>
            <div className="circle4"></div>
          </div>
        <Stack spacing={7.2}>
          <Stack zIndex={0} spacing={7.2}>
            <SubTitle variant="h1">Best AI Tool For Research</SubTitle>
            <Stack>
              <Title
                variant={!medium ? "h1" : "h1"}
                sx={{
                  paddingY: "0px",
                }}
              >
                ResearchPal
              </Title>
            </Stack>
          </Stack>

          <Stack
            width="100%"
            direction={{ xs: "column", md: "row" }}
            justifyContent={"center"}
            spacing={5}
          >
            <Stack alignItems="center" justifyContent="center">
                <a href="https://veracious.ai/" target="blank">
                  <BtnPowered variant="contained">
                    Powered By Veracious
                    <ArrowOutwardIcon fontSize="small" sx={{ ml: 1 }} />
                  </BtnPowered>
                </a>
              </Stack>
            <Stack alignItems="center" justifyContent="center" width={"100%"}>
              <BtnStart
                variant="contained"
                onClick={() => toggle("loginModal")}
                fullWidth
              >
                Start Writing
                <CreateIcon fontSize="small" sx={{ ml: 1 }} />
              </BtnStart>
            </Stack>
          </Stack>
        </Stack>
        </Grid>
        <Grid md={6} xs={12} sx={{ display: "flex", alignItems: "center" }}>
        <VideoBox>
          <video
            src={homepageVideo}
            type="video/mp4"
            autoPlay
            loop
            muted
            style={{ borderRadius: "8px" }}
          >
            <source  />
            Your browser does not support the video.
          </video>
        </VideoBox>
        </Grid>
      </Grid>
      </Box> */}

      {state.loginModal && (
        <Login open={state.loginModal} onClose={(e) => toggle(e)} />
      )}
      {state.forgotModal && (
        <ForgotPassword
          open={state.forgotModal}
          onClose={() => toggle("forgotModal")}
        />
      )}
      {state.signupModal && (
        <Signup
          open={state.signupModal}
          onClose={() => toggle("signupModal")}
        />
      )}
    </RootStyle>
  );
};

export default HomeSection;
