import {
  Box,
  Chip,
  Grid,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FreeCard from "./FreeCard";
import { Features } from "./features";
import {
  BtnSub,
  CustomBox,
  CustomGrid,
  HeadingText,
  StyledTableCell,
  StyledTableRow,
  Text,
} from "./styles";
import StandardCard from "./StandardCard";
import ProCard from "./ProCard";
import { Apps, MonetizationOn } from "@mui/icons-material";
import { useSelector } from "react-redux";
import Contact from "../Contact";

const NewCards = ({ onSelect }) => {
  const isSmallScreen = useMediaQuery("(max-width:900px)");
  const [tabValue, setTabValue] = useState(0);
  const { user } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen((prev) => !prev);
  };

  const [billingPeriod, setBillingPeriod] = useState(
    user.subscription?.price === 99 ? "yearly" : "monthly"
  );
  const [proBillingPeriod, setProBillingPeriod] = useState(
    user.subscription?.price === 359.88 ? "yearly" : "monthly"
  );

  const handleBillingPeriodChange = (event, newPeriod) => {
    if (newPeriod !== null) {
      setBillingPeriod(newPeriod);
    }
  };
  const handleProBillingPeriodChange = (event, newPeriod) => {
    if (newPeriod !== null) {
      setProBillingPeriod(newPeriod);
    }
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (isSmallScreen) {
      setTabValue(0);
    }
  }, [isSmallScreen]);
  //function that convert Nan to unlimitedt text
  const convertToUnlimited = (value) => {
    return isNaN(value) ? value : value;
  };
  return (
    <CustomGrid container>
      <Grid item md={12} xs={12}>
        <Stack justifyContent={"center"} alignItems={"center"}>
          <HeadingText
            sx={{
              color: "#14224B",
              fontWeight: 400,
              fontFamily: "Righteous",
              fontSize: "32px",
              textAlign: "center",
              // pt: { xs: 0, md: 4.4 },
              pt: { xs: 0, md: 0 },
              pb: { xs: 4, md: 0 },
            }}
          >
            Pricing
          </HeadingText>
          {/* <HeadingText>Choose a Plan</HeadingText>
          <Text>
            Switch to standard plan to access advanced features at any time.
          </Text> */}
        </Stack>
      </Grid>
      {isSmallScreen ? (
        <>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant="fullWidth"
              scrollButtons="auto"
              sx={{
                backgroundColor: "#e5eaf2",
                width: "100%",
                borderRadius: "10px",
              }}
            >
              <Tab label="Free Plan" wrapped />
              <Tab label="Standard Plan" wrapped />
              <Tab label="Pro Plan" wrapped />
            </Tabs>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {tabValue === 0 && (
              <Box
                sx={{
                  border: "1px  solid #e5eaf2",
                  borderRadius: "10px",
                  padding: "10px",
                  width: "100%",
                  background: { xs: "white", md: "none" },
                }}
              >
                <FreeCard />
              </Box>
            )}
            {tabValue === 1 && (
              <Box
                sx={{
                  border: "1px  solid #e5eaf2",
                  borderRadius: "10px",
                  padding: "10px",
                  width: "100%",
                  background: { xs: "white", md: "none" },
                }}
              >
                <StandardCard
                  onSelect={onSelect}
                  billingPeriod={billingPeriod}
                  handleBillingPeriodChange={handleBillingPeriodChange}
                />
              </Box>
            )}
            {tabValue === 2 && (
              <Box
                sx={{
                  border: "1px  solid #e5eaf2",
                  borderRadius: "10px",
                  padding: "10px",
                  width: "100%",
                  background: { xs: "white", md: "none" },
                }}
              >
                <ProCard
                  onSelect={onSelect}
                  billingPeriod={proBillingPeriod}
                  handleBillingPeriodChange={handleProBillingPeriodChange}
                />
              </Box>
            )}
          </Grid>
        </>
      ) : null}
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        mt={2}
        sx={{
          border: "1px solid #e5eaf2",
          borderRadius: "12px",
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
          padding: "10px",
          backgroundColor: "#e5eaf2",
        }}
      >
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Apps sx={{ color: "#14224B !important" }} />
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"100%"}
          >
            <HeadingText>ResearchPal Features</HeadingText>
            {!isSmallScreen && (
              <Stack
                direction={isSmallScreen ? "column" : "row"}
                justifyContent={"center"}
                alignItems={"center"}
                spacing={2}
              >
                <HeadingText>Need a custom plan?</HeadingText>
                <BtnSub
                  sx={{
                    backgroundColor: "#1E4F6B !important",
                    color: "#fff",
                  }}
                  // onClick={() => onSelect("enterprise")}
                  onClick={toggle}
                >
                  Contact Us
                </BtnSub>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Grid>
      {open && <Contact open={open} onClose={toggle} />}
      {isSmallScreen && (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          mt={2}
          sx={{
            border: "1px solid #F3F3F3",
            borderRadius: "12px",
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
            backgroundColor: "#F3F3F3",
          }}
        >
          <Stack alignItems={"center"} spacing={1} p={1}>
            <HeadingText>Playing Big? Get a custom plan.</HeadingText>
            <BtnSub
              // onClick={() => onSelect("enterprise")}
              onClick={toggle}
              sx={{
                // backgroundColor: "#353535 !important",
                backgroundColor: "#1E4F6B !important",
                color: "#f3f3f3",
              }}
            >
              Contact Us
            </BtnSub>
          </Stack>
        </Grid>
      )}
      {!isSmallScreen ? (
        <TableContainer component={Paper}>
          <Table
            sx={{
              border: "0px",
            }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  sx={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#14224B !important",
                  }}
                >
                  <MonetizationOn />
                  Plans
                </StyledTableCell>
                <StyledTableCell>
                  <FreeCard />
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    borderLeft: "1px solid #e5eaf2 !important",
                    borderRight: "1px solid #e5eaf2 !important",
                  }}
                >
                  <StandardCard
                    onSelect={onSelect}
                    billingPeriod={billingPeriod}
                    handleBillingPeriodChange={handleBillingPeriodChange}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <ProCard
                    onSelect={onSelect}
                    billingPeriod={proBillingPeriod}
                    handleBillingPeriodChange={handleProBillingPeriodChange}
                  />
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                borderTop: "0px",
              }}
            >
              {Features.map((feature) => (
                <StyledTableRow key={feature.id}>
                  <StyledTableCell sx={{ fontWeight: 600 }}>
                    {feature.title}
                  </StyledTableCell>
                  <StyledTableCell sx={{ textAlign: "center" }}>
                    <Chip label={feature.freePlandescription} />
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      borderLeft: "1px solid #e5eaf2 !important",
                      borderRight: "1px solid #e5eaf2 !important",
                      textAlign: "center",
                    }}
                  >
                    <Chip
                      label={
                        billingPeriod === "yearly"
                          ? convertToUnlimited(
                              typeof feature.standardPlandescription ===
                                "string"
                                ? feature.standardPlandescription
                                : feature.standardPlandescription * 12
                            )
                          : convertToUnlimited(
                              typeof feature.standardPlandescription ===
                                "string"
                                ? feature.standardPlandescription
                                : feature.standardPlandescription
                            ) || "-"
                      }
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <Chip
                      label={
                        proBillingPeriod === "yearly"
                          ? convertToUnlimited(
                              typeof feature.proPlandescription === "string"
                                ? feature.proPlandescription
                                : feature.proPlandescription * 3 * 12
                            )
                          : convertToUnlimited(
                              typeof feature.proPlandescription === "string"
                                ? feature.proPlandescription
                                : feature.proPlandescription * 3
                            ) || "-"
                      }
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : tabValue === 0 ? (
        Features.map((feature) => (
          <CustomBox
            key={feature.id}
            sx={{ background: { xs: "white", md: "none" } }}
          >
            <Grid item xs={12} sm={6} md={3}>
              <Text
                sx={{
                  fontWeight: 600,
                }}
                p={1}
              >
                {feature.title}
              </Text>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              sx={{
                borderLeft: "1px solid #e5eaf2 !important",
                textAlign: "center",
              }}
            >
              <Text p={1}>{feature.freePlandescription}</Text>
            </Grid>
          </CustomBox>
        ))
      ) : tabValue === 1 ? (
        Features.map((feature) => (
          <CustomBox
            key={feature.id}
            sx={{ background: { xs: "white", md: "none" } }}
          >
            <Grid item xs={12} sm={6} md={3}>
              <Text
                sx={{
                  fontWeight: 600,
                }}
                p={1}
              >
                {feature.title}
              </Text>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              sx={{
                borderLeft: "1px solid #e5eaf2 !important",
                textAlign: "center",
              }}
            >
              <Text p={1}>
                {billingPeriod === "yearly"
                  ? convertToUnlimited(
                      typeof feature.standardPlandescription === "string"
                        ? feature.standardPlandescription
                        : feature.standardPlandescription * 12
                    )
                  : convertToUnlimited(
                      typeof feature.standardPlandescription === "string"
                        ? feature.standardPlandescription
                        : feature.standardPlandescription
                    ) || "-"}
              </Text>
            </Grid>
          </CustomBox>
        ))
      ) : tabValue === 2 ? (
        Features.map((feature) => (
          <CustomBox
            key={feature.id}
            sx={{ background: { xs: "white", md: "none" } }}
          >
            <Grid item xs={12} sm={6} md={3}>
              <Text
                sx={{
                  fontWeight: 600,
                }}
                p={1}
              >
                {feature.title}
              </Text>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              sx={{
                borderLeft: "1px solid #e5eaf2 !important",
                textAlign: "center",
              }}
            >
              <Text p={1}>{feature.proPlandescription || "-"}</Text>
            </Grid>
          </CustomBox>
        ))
      ) : null}
    </CustomGrid>
  );
};

export default NewCards;
