import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import ReactGA from "./Analytics";
import AccessDenied from "./pages/AccessDenied/AccessDenied";
import Admin from "./pages/Admin/Admin";
import Feedback from "./pages/Admin/Feedback";
import Protal from "./pages/Admin/Protal";
import ResetPassword from "./pages/Admin/ResetPassword";
import { getUser } from "./pages/Admin/userSlice";
import EmailVerification from "./pages/EmailVerification/EmailVerification";
// import Editor from "./pages/NewEditor";
import LandingPage from "./pages/landing page";
import Library from "./pages/library";
import LiteratureReview from "./pages/literature review/LiteratureDialog";
import PapersInsights from "./pages/paper insights";
import SearchPapers from "./pages/papers";
import Profile from "./pages/profile";
import ProjectsList from "./pages/projects/index";
import References from "./pages/references";
import Sidebar from "./pages/sidebar";
import Subscription from "./pages/subscription";
import TipTapEditor from "./pages/editor";
// import Library from "./pages/library";
// import SearchPapers from "./pages/papers";
// import PapersInsights from "./pages/paper insights";
// import LiteratureReview from "./pages/literature review/LiteratureDialog";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import API from "./pages/API/API";
import Layout3 from "./pages/API/Sidebar/Layout3";
import SemanticItems from "./pages/papers/SemanticItems";
import Universities from "./pages/landing page/universities";
import FAQsComp from "./pages/landing page/faqs/index";
import Pricing from "./pages/landing page/pricing";
import Features from "./pages/landing page/features";
import ApiSiderBar from "./pages/API/Sidebar/Layout3";
import TermsAndConditions from "./pages/landing page/term conditions";
import ResearchpalPapers from "./pages/papers/ResearchpalPapers";
import MaintenancePage from "./pages/maintenance";
import Blog from "./pages/landing page/blog";
function App() {
  const dispatch = useDispatch();
  const loggedIn = localStorage.getItem("loggedIn");
  const pathname = window.location.pathname;
  const { user, isLoggedIn } = useSelector((state) => state.user);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: pathname,
      title: "App",
    });

    if (loggedIn && user == "") {
      dispatch(getUser());
    }
  }, []);

  const Projects = () => {
    return (
      <Routes>
        <Route path="/" element={<ProjectsList />} />
        <Route path="/:id/*" element={<ProjectDetails />} />
      </Routes>
    );
  };
  const ProjectDetails = () => {
    return (
      <Box sx={{ width: "100%", display: "flex", alignItems: "stretch" }}>
        <Box sx={{ flex: "0 0 auto" }}>
          <Sidebar />
        </Box>
        <Box sx={{ flex: "1 1 auto", overflowX: "auto" }}>
          <Routes>
            <Route path="*" element={<TipTapEditor />} />
            <Route path="document" element={<TipTapEditor />} />
            <Route path="literature-review" element={<LiteratureReview />} />
            <Route path="references" element={<References />} />
            {/* <Route path="search-papers" element={<SearchPapers />} /> */}
            <Route path="search-papers" element={<SearchPapers />} />
            <Route path="search-papers/:id" element={<SemanticItems />} />
            <Route path="papers-insights" element={<PapersInsights />} />
            <Route path="library" element={<Library />} />
          </Routes>
        </Box>
      </Box>
    );
  };

  const UserRoutes = () => {
    return (
      <Routes>
        <Route exact path="/subscription/profile" element={<Profile />} />
        <Route exact path="/projects/*" element={<Projects />} />
        <Route exact path="/subscription" element={<Subscription />} />
        <Route exact path="/projects" element={<ProjectsList />} />
        {/* <Route exact path="/mantainance" element={<MaintenancePage />} /> */}
        <Route path="*" element={<Navigate to="/projects" />} />
      </Routes>
    );
  };
  const AdminRoutes = () => {
    return (
      <Routes>
        <Route exact path="/admin/verify-users" element={<Protal />} />
        <Route exact path="/admin/feedback" element={<Feedback />} />
        <Route path="*" element={<Navigate to="/admin/verify-users" />} />
      </Routes>
    );
  };
  const LandingRoutes = () => {
    return (
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route
          exact
          path="/email-verification"
          element={<EmailVerification />}
        />
        <Route
          exact
          path="/api"
          element={<ApiSiderBar children={<API />} />}
        ></Route>
        <Route
          exact
          path="/termsandconditions"
          element={<TermsAndConditions />}
        />
        <Route exact path="/universities" element={<Universities />} />
        <Route exact path="/faqs" element={<FAQsComp />} />
        <Route exact path="/pricing" element={<Pricing />} />
        {/* <Route exact path="/blog" element={<Blog />} /> */}
        <Route exact path="/features" element={<Features />} />
        <Route exact path="/access-denied" element={<AccessDenied />} />
        <Route exact path="/reset-password" element={<ResetPassword />} />
        <Route exact path="/admin" element={<Admin />} />
        {/* <Route exact path="/mantainance" element={<MaintenancePage />} /> */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    );
  };

  return (
    <Box>
      {!isLoggedIn && (
        <Routes>
          <Route path="/*" element={<LandingRoutes />} />
        </Routes>
      )}
      {isLoggedIn == "user" && (
        <Routes>
          <Route path="/*" element={<UserRoutes />} />
        </Routes>
      )}
      {isLoggedIn == "admin" && (
        <Routes>
          <Route path="/*" element={<AdminRoutes />} />
        </Routes>
      )}
    </Box>
  );
}

export default App;
