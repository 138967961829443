import React, { useState } from "react";
import { TextField, Button, useMediaQuery } from "@mui/material";
import { Dialog, DialogContent, Stack, Typography } from "@mui/material";
import Repo from "../../repo/Repo";
import TailSpinLoader from "../../components/TailSpinLoader";
import Toast from "../../components/Toast";
import { MailOutline } from "@mui/icons-material";
import { Text } from "../landing page/cards/styles";

function Contact({ open, onClose }) {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const [state, setState] = useState({
    loading: false,
    email: "",
    message: "",
  });
  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };
  const toggle = () => {
    if (!state.loading) {
      onClose();
    }
  };
  const handleSubmit = async () => {
    setState((prev) => ({ ...prev, loading: true }));
    let { data } = await Repo.email({
      request: {
        method: "sendMessage",
        data: {
          to: "info@researchpal.co",
          message: state.message,
          from: state.email,
        },
      },
    });
    setState((prev) => ({ ...prev, loading: false }));
    if (data.response.data.success === true) {
      Toast("success", "Email Submitted Successfully");
      toggle();
    } else {
      Toast("error", "Email Not Submitted ");
    }
  };
  return (
    <Dialog open={open} onClose={toggle} fullWidth>
      <DialogContent>
        <Stack padding="5px" minHeight="300px" spacing={5}>
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            Contact Us
          </Typography>
          <Stack
            spacing={1.6}
            alignItems="center"
            justifyContent="center"
            direction={isSmallScreen ? "column" : "row"}
          >
            <Text sx={{ fontWeight: 700 }}>Get in touch</Text>

            <Stack direction="row" spacing={1}>
              <MailOutline sx={{ mt: "2px", fontSize: "18px" }} />
              <Text>Email : Info@ResearchPal.co</Text>
            </Stack>
          </Stack>
          <TextField
            fullWidth
            name="email"
            label="Enter your email"
            type="email"
            value={state.email}
            onChange={handleInput}
          />
          <TextField
            fullWidth
            multiline
            minRows={6}
            maxRows={7}
            name="message"
            label="Message"
            type="text"
            value={state.message}
            onChange={handleInput}
          />
          {state.loading && <TailSpinLoader />}
          {!state.loading && (
            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
              spacing={{ xs: 1 }}
            >
              <Button
                variant="outlined"
                onClick={toggle}
                sx={{
                  color: "#353535",
                  borderColor: "#353535",
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
                sx={{
                  background: "linear-gradient(90deg, #5671F1, #17CEAD)",
                }}
              >
                Submit
              </Button>
            </Stack>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default Contact;
