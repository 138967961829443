import React, { useState } from "react";
import { Box, Card, CardContent, CardMedia, styled } from "@mui/material";
import { Grid, Typography } from "@mui/material";
import ProjectLibrary from "../../../assets/images/NewImages/project-library-illustration.png";
import LiteratureReview from "../../../assets/images/NewImages/literature-review-illustration.png";
import TextEditor from "../../../assets/images/NewImages/text-editor-illustration.png";
import SearchImage from "../../../assets/images/NewImages/search-illustration.png";
import AskResearch from "../../../assets/images/NewImages/ask-researchpal-illustration.png";
import TextCitation from "../../../assets/images/NewImages/text-citations-illustration.png";
import Blog1 from "../../../assets/images/NewImages/blog1.webp";
import Blog2 from "../../../assets/images/NewImages/blog2.webp";
import Blog3 from "../../../assets/images/NewImages/blog3.webp";
import { TypeAnimation } from "react-type-animation";
import { useLocation } from "react-router-dom";
import Login from "../../authentication/login";
import ForgotPassword from "../../Admin/ForgotPassword";
import Signup from "../../authentication/sign up";
import LandingHeader from "../../../layout/header";
import Footer from "../../../layout/footer";

const RootStyle = styled(Box)(({ theme }) => ({
  minHeight: "700px",
  background: "#fff",
  // marginTop: "10vh",
  padding: "24px 30px 0 30px",
  [theme.breakpoints.down("md")]: {
    padding: "0 10px 64px 10px",
  },
}));
const CustomCard = styled(Card)({
  //   margin: "0 auto",
  maxWidth: "340px",
  //   padding: "8px",
  borderRadius: "8px",
  height: "500px",

  overflow: "hidden", // To ensure the image doesn't overflow
  "&:hover .zoom-image": {
    transform: "scale(1.1)", // Zoom effect on hover
    backgroundPosition: "50%", // Control background positioning
    transitionDuration: "0.8s", // Slow zoom-in effect
    transitionTimingFunction: "cubic-bezier(.075, .82, .165, 1)", // Custom easing function
  },

  //   backdropFilter: "blur(12.17898178100586px)",
});

const ImageStyle = styled(CardMedia)({
  height: 200,
});

const Title = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 600,
  fontSize: "22px",
  marginBottom: "8px",
});
const CardText = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 400,
  fontSize: "16px",
  //justify text to justified
  lineHeight: "24px",
});

function Blog() {
  const location = useLocation();

  const [state, setState] = useState({
    loginModal: location.state ? true : false,
    forgotModal: false,
    signupModal: false,
    contactUsModal: false,
  });
  const toggle = (name) => {
    if (name === "forgotModal" || name === "signupModal") {
      setState((prev) => ({ ...prev, [name]: !prev[name], loginModal: false }));
    } else {
      setState((prev) => ({ ...prev, [name]: !prev[name] }));
    }
  };
  return (
    <Box>
      <Box
        sx={{
          zIndex: 1,
          p: 2,
          position: "sticky",
          right: 5,
          left: 5,
          top: 1,
          backgroundColor: "rgba(254, 254, 254, 0.32)",
          backdropFilter: "blur(12.17898178100586px)",
        }}
      >
        <LandingHeader onClick={(e) => toggle(e)} />
      </Box>
      <RootStyle>
        <Typography
          sx={{
            color: "#14224B",
            fontWeight: 400,
            fontFamily: "Righteous",
            fontSize: "32px",
            textAlign: "center",
            mb: "24px",
          }}
        >
          Blog
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 4,
            justifyContent: "center",
            alignItems: "center",
            p: 2,
          }}
        >
          <Box>
            <CustomCard>
              {/* <CardMedia sx={{ height: 200 }} image={Blog1} title="preview" />
               */}
              <ImageStyle
                className="zoom-image"
                image={Blog1}
                title="preview"
              />
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 3,
                  //   height: "100%",
                }}
              >
                <Title>What is the Tipping Point for SEO & How It Works</Title>
                <CardText>
                  The tipping point is a term we’ve coined to describe when your
                  organic search traffic chart shows a steep climb up. You
                </CardText>
              </CardContent>
            </CustomCard>
          </Box>

          <Box>
            <CustomCard>
              {/* <CardMedia sx={{ height: 200 }} image={Blog2} title="preview" />
               */}
              <ImageStyle
                className="zoom-image"
                image={Blog2}
                title="preview"
              />
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 3,
                  //   height: "100%",
                }}
              >
                <Title>
                  How Hiring An SEO Agency Can Generate Profitable Business
                  Growth
                </Title>
                <CardText>
                  Boost online visibility! Find success by hiring an SEO agency.
                  Elevate rankings, and dominate search results with expert
                  assistance
                </CardText>
              </CardContent>
            </CustomCard>
          </Box>

          <Box>
            <CustomCard>
              {/* <CardMedia sx={{ height: 200 }} image={Blog3} title="preview" />
               */}
              <ImageStyle
                className="zoom-image"
                image={Blog3}
                title="preview"
              />
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 3,
                  //   height: "100%",
                }}
              >
                <Title>12 Tips for Hiring SEO Experts In 2024 </Title>
                <CardText>
                  Boost your online presence with expert SEO! Find top talent
                  for hiring SEO professionals. Elevate your website rankings
                  and visibility.
                </CardText>
              </CardContent>
            </CustomCard>
          </Box>
        </Box>

        {state.loginModal && (
          <Login open={state.loginModal} onClose={(e) => toggle(e)} />
        )}
        {state.forgotModal && (
          <ForgotPassword
            open={state.forgotModal}
            onClose={() => toggle("forgotModal")}
          />
        )}
        {state.signupModal && (
          <Signup
            open={state.signupModal}
            onClose={() => toggle("signupModal")}
          />
        )}
      </RootStyle>
      <Footer />
    </Box>
  );
}

export default Blog;
