import React, { useEffect, useState } from "react";
import { Article, DataObject, FileOpen } from "@mui/icons-material";
import {
  Image,
  InsertLink,
  PictureAsPdf,
  IntegrationInstructions,
} from "@mui/icons-material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, CircularProgress } from "@mui/material";
import { FormControl, FormControlLabel, InputAdornment } from "@mui/material";
import { Radio, RadioGroup, Stack } from "@mui/material";
import { TextField, Tooltip, Typography } from "@mui/material";
import AWS from "aws-sdk";
import moment from "moment/moment";
import DataTable from "react-data-table-component";
import { pdfjs } from "react-pdf";
import { useDispatch, useSelector } from "react-redux";
import MendeleyIcon from "../../assets/images/mendeley icon.png";
import RefsIcon from "../../assets/images/NewImages/insightButton.svg";
import ZoterIcon from "../../assets/images/zotero icon.png";
import TailSpinLoader from "../../components/TailSpinLoader";
import Toast from "../../components/Toast";
import Repo from "../../repo/Repo";
import { MendeleyDialogue } from "../mendeley";
import {
  addForInsights,
  addInsightsProject,
} from "../paper insights/insightsSlice";
import { clearRefs } from "../references/refsSlice";
import { handleSelectedCom } from "../sidebar/toggleSlice";
import { ZoteroDialogue } from "../zotero/ZoteroDialogue";
import ConfirmationDialog from "./Delete";
import UploadFile from "./UploadFile";
import { Btn, ImgBox, Text, customStyles } from "./style";

import { useLocation, useNavigate } from "react-router-dom";
import ReactGA from "../../Analytics";
import { getUser } from "../Admin/userSlice";
import AbstractDialogue from "./AbstractDialogue";
import UploadPdfDialogue from "./UploadPdfDialogue";
import { TailSpin } from "react-loader-spinner";
import ImageDialogue from "./ImageDialogue";
import {
  openDocFromS3,
  openFileFromS3,
  openHtmlFromS3,
  openImageFromS3,
  openJsonFromS3,
  openMetaDataFromS3,
  openXlsxFromS3,
} from "./s3";
import { set } from "lodash";
import {
  cancelEmbed,
  fetchResearchReferences,
  saveResearchReferences,
} from "../../apiservice";
import SaveReferenceDialogue from "./SaveReferenceDialogue";
import { getRefs } from "../references/refsSlice";
import ConfirmationDialogAbstracts from "./DeleteAbstract";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

const customNoDataComponent = (arg, view) => (
  <Box sx={{ p: "16px", display: "flex", alignItems: "center" }}>
    {arg ? (
      <Typography sx={{ fontSize: "16px", fontWeight: 500 }} align="center">
        Processing papers and fetching references, please wait ...
      </Typography>
    ) : (
      <Typography sx={{ fontSize: "16px", fontWeight: 500 }} align="center">
        No records
      </Typography>
    )}
  </Box>
);

function Library() {
  let projectTitle = localStorage.getItem("projectTitle");
  let projectId = localStorage.getItem("projectId");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const { insightsProjects } = useSelector((state) => state.insights);
  const { refFormats } = useSelector((state) => state.refs);
  console.log("RefFormats", refFormats);
  let currentProject = insightsProjects.find((i) => i?.title == projectTitle);

  let searchedKeys = currentProject?.searchedKeys || [];
  const { zoteroLoading, paperLoading } = useSelector((state) => state.loader);
  const { mendelyLoading } = useSelector((state) => state.loader);
  const [value, setValue] = useState("uploads");
  const [filterText, setFilterText] = useState("");
  console.log("Filtered text:", filterText);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [filesData, setFilesData] = useState([]);
  console.log("FileData", filesData);
  // const tableHeight = Object.keys(filesData).length <= 5 ? "auto" : "70vh";

  console.log("Files Data are:", filesData);
  const [loading, setLoading] = useState(false);
  const [viewLoading, setViewLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  console.log("Selected", selected);
  const [zoModal, setZoModal] = useState(false);
  const [mendModal, setMendModal] = useState(false);
  const [remModal, setRemModal] = useState(false);
  const [zoteroModal, setZoteroModal] = useState(
    localStorage.getItem("zoteroModal") == "true" ? true : false
  );
  const [selRow, setSelRow] = useState("");
  console.log("selRow", selRow);

  let keys = [];
  if (selRow) {
    keys.push(selRow.pdfFile);
    setSelRow("");
    console.log("Pdf Files are", keys);
  }

  const [absModal, setAbsModal] = useState(false);
  const [abstracts, setAbstracts] = useState("");
  console.log("Abstracts", abstracts);
  const [open, setOpen] = React.useState(false);
  const [openSecondDialogue, setOpenSecondDialogue] = React.useState(false);
  const [AbstractData, setAbstractData] = useState({});
  const location = useLocation();
  const [imageUrl, setImageUrl] = useState("");
  const [imageDialogue, setImageDialog] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [modal, setModal] = useState(false);
  const [refLoading, setRefLoading] = useState(false);
  const [ref, setRef] = useState([]);
  let abstractKeys = Object.keys(abstracts);
  console.log("Abstract keys", abstractKeys);
  let referenceToDelete = Object.keys(refFormats).filter((refKey) => {
    let harvardValue = refFormats[refKey].harvard;
    return abstractKeys.includes(harvardValue);
  });
  console.log("ReftoDelete", referenceToDelete);

  // Ensure abstracts is an object and has valid keys
  let refKeys = referenceToDelete
    .filter((refKey) => {
      const harvardValue = refFormats[refKey].harvard.trim(); // Ensure no leading/trailing spaces
      console.log("Harvard", harvardValue);
      return abstractKeys.includes(harvardValue); // Ensure Harvard value exists in abstracts
    })
    .map((refKey) => refKey) // Map to refFormats keys
    .filter((key) => key !== undefined); // Ensure no undefined keys

  console.log("RefKeys", refKeys);

  let keySel = []; // Declare keySel array outside the loop

  selected.forEach((item) => {
    let key = Object.keys(abstracts).find(
      (key) => abstracts[key].title === item.title
    );
    if (key) keySel.push(key); // Add key to keySel array if it exists
  });

  // absToggle();
  console.log("KeySel", keySel);
  const numberOfFiles = Object.keys(filesData).length;

  // Calculate the table height based on the length of filesData
  const tableHeight =
    value === "uploads" ? (numberOfFiles <= 6 ? "auto" : "55vh") : "auto";
  const [fetchRefsLoadingMap, setFetchRefsLoadingMap] = useState({});
  const zoteroKey = window.location.search;
  const isRowLoading = (file) => fetchRefsLoadingMap[file];
  const handleClose = async () => {
    // setOpen(false);
    // const info = {
    //   deleteEmbeddings: true,
    //   email: localStorage.getItem("email"),
    //   title: localStorage.getItem("projectTitle"),
    // };
    // await cancelEmbed(info);
    // if (imageDialogue === true) {
    //   setImageDialog(false);
    // }
    if (imageDialogue) {
      setOpen(false);
      setImageDialog(false);
    } else {
      setOpen(false);
      const info = {
        deleteEmbeddings: true,
        email: localStorage.getItem("email"),
        title: localStorage.getItem("projectTitle"),
      };
      await cancelEmbed(info);
    }
  };
  const handleCloseSecondDialogue = () => {
    setOpenSecondDialogue(false);
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Library",
    });
    getProject();
    getFiles();
    if (!user) {
      dispatch(getUser());
    }
  }, []);
  useEffect(() => {
    if (!currentProject) {
      dispatch(addInsightsProject(localStorage.getItem("projectTitle")));
    }
  }, [insightsProjects]);

  const columns = [
    {
      name: "#",
      width: "50px",
      cell: (row) => {
        //get serial number from row index
        let index = Object.keys(filesData).findIndex(
          (key) => filesData[key] === row
        );
        return index + 1;
      },
    },
    {
      // name: "Icon",
      width: "50px",
      cell: (row) => {
        if (
          row?.title?.split(".")?.pop() == "htm" ||
          row?.title?.split(".")?.pop() == "html"
        ) {
          return (
            <IntegrationInstructions
              sx={{
                color: "#E85757",
              }}
            />
          );
        } else if (
          row?.title?.split(".")?.pop() == "jpg" ||
          row?.title?.split(".")?.pop() == "jpeg" ||
          row?.title?.split(".")?.pop() == "png"
        ) {
          return (
            <Image
              sx={{
                color: "#53A8CB",
              }}
            />
          );
        } else if (
          row?.title?.split(".")?.pop() == "doc" ||
          row?.title?.split(".")?.pop() == "docx"
        ) {
          return <Article />;
        } else if (
          row.title?.split(".").pop() == "xls" ||
          row.title?.split(".").pop() == "xlsx"
        ) {
          return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12em"
              height="4em"
              viewBox="0 0 18 18"
            >
              <path
                fill="currentColor"
                d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8zm1.8 18H14l-2-3.4l-2 3.4H8.2l2.9-4.5L8.2 11H10l2 3.4l2-3.4h1.8l-2.9 4.5zM13 9V3.5L18.5 9z"
              />
            </svg>
          );
        } else if (row.pdfFile?.match(/\.([^.]+)$/)[1] == "pdf") {
          return (
            <PictureAsPdf
              sx={{
                color: "#E85757",
              }}
            />
          );
        } else if (row?.title?.split(".").pop() == "json") {
          return (
            <DataObject
              sx={{
                color: "#e0ac69",
              }}
            />
          );
        } else {
          return (
            <InsertLink
              sx={{
                color: "blue",
              }}
            />
          );
        }
      },
    },
    {
      name: "TITLE",
      width: "220px",
      cell: (row) => {
        return (
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              row?.title?.split(".").pop() == "htm" ||
              row.pdfFile.match(/\.([^.]+)$/)[1] == "html"
                ? handleHtmlView(row)
                : row?.title?.split(".")?.pop() == "jpg" ||
                  row?.title?.split(".")?.pop() == "jpeg" ||
                  row?.title?.split(".")?.pop() == "png"
                ? handleImageView(row)
                : row?.title?.split(".")?.pop() == "doc" ||
                  row?.title?.split(".")?.pop() == "docx"
                ? handleDoc(row)
                : row?.title?.split(".")?.pop() == "xls" ||
                  row?.title?.split(".")?.pop() == "xlsx"
                ? handleXlsView(row)
                : row.pdfFile.match(/\.([^.]+)$/)[1] == "pdf"
                ? handleView(row)
                : handleMetaData(row);
            }}
          >
            {
              //apply logic if title extension is html then open htmlUrl and if image then open imageUrl and if pdf then open pdf
              row?.title
            }
          </div>
        );
      },
      sortable: true,
    },

    {
      name: "YEAR",
      width: "90px",
      selector: (row) => row.year,
      sortable: true,
    },
    {
      name: "AUTHORS",
      width: "150px",
      selector: (row) => row.author,
      sortable: true,
    },
    {
      name: "SOURCE",
      width: "190px",
      selector: (row) => (
        <a
          style={{ color: "blue" }}
          href={row?.link || row?.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {row?.link || row?.url}
        </a>
      ),
      sortable: true,
    },
    {
      name: "ADDED",
      width: "100px",
      selector: (row) => moment.unix(row?.uploadDate).format("DD-MM-YYYY"),
      sortable: true,
    },
    {
      name: "REMOVE",
      width: "100px",
      cell: (row) => (
        <ImgBox onClick={() => remToggle(row)}>
          <DeleteForeverIcon
            sx={{ color: "#EB5757", fontSize: "20px", ursor: "pointer" }}
          />
        </ImgBox>
      ),
    },

    {
      name: "Papers Insights",
      center: true,
      cell: (row) => (
        <ImgBox
          sx={{
            background: searchedFiles[row.pdfFile] ? "#D9F6D6" : "#ECEBEB",
            color: searchedFiles[row.pdfFile] ? "#6AB164" : "#A5A5A5",
            width: "86px",
            fontFamily: "Raleway",
            fontSize: "12px",
            fontWeight: 600,
            cursor: "default",
          }}
        >
          {searchedFiles[row.pdfFile] ? "Added" : "Not Added"}
        </ImgBox>
      ),
    },
    // {
    //   name: "Fetch References",
    //   center: true,
    //   cell: (row) => (
    //     <Button
    //       sx={{
    //         background: "#353535",
    //         color: "#fff",
    //         width: "86px",
    //         height: "30px",
    //         fontFamily: "Raleway",
    //         fontSize: "12px",
    //         fontWeight: 600,
    //         cursor: "pointer",
    //         borderRadius: "6px",
    //         "&:hover": {
    //           background: "#353535",
    //         },
    //       }}
    //       onClick={() => {
    //         fetchRefs(row?.pdfFile);
    //       }}
    //     >
    //       {isRowLoading(row.pdfFile) ? ( // Show loader if loading state is true for the specific row
    //         <CircularProgress
    //           size={20}
    //           sx={{ color: "#fff", marginRight: "10px" }}
    //         />
    //       ) : (
    //         "Fetch"
    //       )}
    //     </Button>
    //   ),
    // },
    {
      name: "View File",
      center: true,
      cell: (row) => (
        <ImgBox
          onClick={() => {
            row?.title?.split(".").pop() == "htm" ||
            row?.title?.split(".").pop() == "html"
              ? handleHtmlView(row)
              : row?.title?.split(".").pop() == "jpg" ||
                row?.title?.split(".").pop() == "jpeg" ||
                row?.title?.split(".").pop() == "png"
              ? handleImageView(row)
              : row?.title?.split(".").pop() == "doc" ||
                row?.title?.split(".").pop() == "docx"
              ? handleDoc(row)
              : row?.title?.split(".").pop() == "xls" ||
                row?.title?.split(".").pop() == "xlsx"
              ? handleXlsView(row)
              : row.pdfFile?.match(/\.([^.]+)$/)[1] == "pdf"
              ? handleView(row)
              : handleMetaData(row);
          }}
          sx={{ background: "#ECEBEB" }}
        >
          <FileOpen
            sx={{ color: "#61C6B4", fontSize: "20px", cursor: "pointer" }}
          />
        </ImgBox>
      ),
    },
  ];
  const absColumns = [
    {
      name: "#",
      width: "50px",
      cell: (row) => {
        //get serial number from row index
        let index = Object.keys(abstracts).findIndex(
          (key) => abstracts[key] === row
        );
        return index + 1;
      },
    },

    {
      name: "FILENAME",
      width: "470px",
      selector: (row) => row?.title,
      cell: (row) => {
        return (
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              handleAbstractView(row);
            }}
          >
            {row?.title}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "SOURCE",
      width: "250px",
      selector: (row) => (
        <a
          style={{ color: "blue" }}
          href={row?.url || row?.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {row?.url || row?.link}
        </a>
      ),
      sortable: true,
    },
    {
      name: "SAVED",
      width: "150px",
      selector: (row) =>
        row?.uploadDate
          ? moment.unix(row?.uploadDate).format("DD-MM-YYYY")
          : "",
      sortable: true,
    },
    {
      name: "REMOVE",
      width: "100px",
      cell: (row) => (
        <ImgBox onClick={() => absToggle(row)}>
          <DeleteForeverIcon
            sx={{ color: "#EB5757", fontSize: "20px", ursor: "pointer" }}
          />
        </ImgBox>
      ),
    },
    {
      name: "View File",
      cell: (row) => (
        <ImgBox
          onClick={() => handleAbstractView(row)}
          sx={{ background: "#ECEBEB" }}
        >
          <FileOpen
            sx={{ color: "#353535", fontSize: "20px", cursor: "pointer" }}
          />
        </ImgBox>
      ),
    },
  ];

  const handleAbstractView = (arg) => {
    setOpenSecondDialogue(true);
    setAbstractData(arg);
  };
  const handleView = async (arg) => {
    setViewLoading(true);
    const myPdf = await openFileFromS3(arg);
    if (myPdf) {
      setPdfFile(myPdf);
      setOpen(true);
      localStorage.setItem("FileKey", arg.pdfFile);
      setViewLoading(false);
    }
  };

  const handleImageView = async (arg) => {
    setViewLoading(true);
    const myimage = await openImageFromS3(arg);
    if (myimage) {
      setImageUrl(myimage);
      setImageDialog(true);
      setViewLoading(false);
    }
  };
  const handleHtmlView = async (arg) => {
    setViewLoading(true);
    const myHtml = await openHtmlFromS3(arg);
    if (myHtml) {
      window.open(myHtml, "_blank");
    }
    setViewLoading(false);
  };
  const handleXlsView = async (arg) => {
    setViewLoading(true);
    const myXlsx = await openXlsxFromS3(arg);
    if (myXlsx) {
      const downloadLink = document.createElement("a");
      downloadLink.href = myXlsx;
      downloadLink.download = arg.title;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      setViewLoading(false);
    }
  };

  const handleDoc = async (arg) => {
    setViewLoading(true);
    const myDoc = await openDocFromS3(arg);
    if (myDoc) {
      const reader = new FileReader();
      reader.readAsText(myDoc);
      reader.onload = function () {
        const html = reader.result;
        const newWindow = window.open("", "_blank");
        newWindow.document.write(html);
      };
      setViewLoading(false);
    }
    if (myDoc) {
      const url = URL.createObjectURL(myDoc);
      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = arg.title;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      setViewLoading(false);
    }
  };

  const handleMetaData = async (arg) => {
    setViewLoading(true);
    if (arg.pdfFile.match(/\.([^.]+)$/)[1] === "json") {
      const jsonBlob = await openMetaDataFromS3(arg);
      const url = URL.createObjectURL(jsonBlob);
      window.open(url, "_blank");
      setViewLoading(false);
    } else {
      const jsonBlob = await openMetaDataFromS3(arg);
      if (jsonBlob) {
        const reader = new FileReader();
        reader.readAsText(jsonBlob);
        reader.onload = function () {
          const json = JSON.parse(reader.result);
          if (json.data.url !== "") {
            window.open(json.data.url, "_blank");
          } else window.open(json.data, "_blank");
        };
        setViewLoading(false);
      }
    }
  };
  let searchedFiles = [...searchedKeys].reduce((acc, item) => {
    acc[item] = item;
    return acc;
  }, {});

  const zoToggle = () => {
    // setZoModal((prev) => !prev);
    // dispatch(handleZoteroModal(true));
    localStorage.setItem("zoteroModal", "true");
    setZoteroModal(true);
  };
  const closeToggle = () => {
    localStorage.setItem("zoteroModal", "false");
    setZoteroModal(false);
  };
  const remToggle = (arg = "") => {
    setRemModal((prev) => !prev);
    setSelRow(arg);
  };
  const absToggle = (arg = "") => {
    setAbsModal((prev) => !prev);
    setSelRow(arg);
  };
  const mendToggle = () => {
    setMendModal((prev) => !prev);
  };

  const handleRadio = (e) => {
    const { value } = e.target;
    setValue(value);
    if (selected.length > 0) {
      setSelected([]);
      setToggleClearRows(false);
    }
  };

  const getProject = async () => {
    try {
      setLoading(true);
      let { data } = await Repo.research({
        request: {
          method: "getProjectsById",
          data: {
            _id: projectId,
          },
        },
      });
      setLoading(false);
      if (data.response.data.projects) {
        setAbstracts(data.response.data.projects?.abstracts || "");
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  const getFiles = async () => {
    try {
      setLoading(true);
      let { data } = await Repo.research({
        request: {
          method: "getPdfData",
          data: {
            researchProjectId: projectId,
          },
        },
      });
      dispatch(getRefs(localStorage.getItem("projectId")));
      setLoading(false);
      if (data.response.status.statusCode === 200) {
        setFilesData(data.response.data.projObj);
      } else {
        Toast("error", "Error");
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      Toast("error", err);
    }
  };
  const deleteFiles = async (remRefs, includeReferences) => {
    try {
      let keys = [];
      if (selRow) {
        keys.push(selRow.pdfFile);
        setSelRow("");
      } else {
        keys = selected.map((i) => i.pdfFile);
        setSelected([]);
      }
      setLoading(true);
      remToggle();
      let { data } = await Repo.research({
        request: {
          method: "deletePdfData",
          data: {
            researchProjectId: projectId,
            s3Keys: keys,
            // isReferences: remRefs.length > 0 ? true : false,
            // isReferences: keys.length > 0 ? true : false,
            isReferences: includeReferences,
            // references: remRefs,
            // references: keys,
            references: includeReferences ? keys : [],
          },
        },
      });
      setLoading(false);
      if (remRefs.length > 0) {
        dispatch(clearRefs());
      }
      if (data.response.status.statusCode === 200) {
        Toast("success", "Files Deleted Successfully");
        getFiles();
      } else {
        Toast("error", "Error");
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      Toast("error", err);
    }
  };

  const getName = (obj) => {
    let val = "";
    for (const key in obj) {
      val = obj[key];
      break;
    }
    return val;
  };

  const handleInput = (e) => {
    const { value } = e.target;
    // setFilterText(value);
    setFilterText(value.toLowerCase());
  };

  const rowSelection = (arg) => {
    console.log(arg);
    setSelected(arg.selectedRows);
  };

  // const filtered = () => {
  //   if (value == "uploads") {
  //     let arr = [];

  //     if (filterText) {
  //       for (const key in filesData) {
  //         if (
  //           getName(filesData[key])
  //             .toString()
  //             .toLowerCase()
  //             .includes(filterText)
  //         ) {
  //           arr.push(filesData[key]);
  //         } else if (
  //           filesData[key].author.toString().toLowerCase().includes(filterText)
  //         ) {
  //           arr.push(filesData[key]);
  //         }
  //       }
  //       return arr;
  //     } else {
  //       for (const key in filesData) {
  //         arr.push(filesData[key]);
  //       }
  //       return arr;
  //     }
  //   } else {
  //     let arr = [];

  //     if (abstracts) {
  //       if (filterText) {
  //         for (const key in abstracts) {
  //           if (
  //             abstracts[key].title.toString().toLowerCase().includes(filterText)
  //           ) {
  //             arr.push(abstracts[key]);
  //           }
  //         }
  //         return arr;
  //       } else {
  //         for (const key in abstracts) {
  //           arr.push(abstracts[key]);
  //         }
  //         return arr;
  //       }
  //     } else {
  //       return arr;
  //     }
  //   }
  // };

  // const filtered = () => {
  //   let arr = [];

  //   if (filterText) {
  //     for (const key in filesData) {
  //       if (
  //         filesData[key].title.toString().toLowerCase().includes(filterText) ||
  //         filesData[key].author.toString().toLowerCase().includes(filterText) ||
  //         filesData[key].year.toString().toLowerCase().includes(filterText) ||
  //         filesData[key].url.toString().toLowerCase().includes(filterText)
  //       ) {
  //         arr.push(filesData[key]);
  //       }
  //     }
  //     return arr;
  //   } else {
  //     for (const key in filesData) {
  //       arr.push(filesData[key]);
  //     }
  //     return arr;
  //   }
  // };
  const filtered = () => {
    let arr = [];

    if (value == "uploads") {
      if (filterText) {
        for (const key in filesData) {
          if (
            filesData[key].title
              .toString()
              .toLowerCase()
              .includes(filterText) ||
            filesData[key].author
              .toString()
              .toLowerCase()
              .includes(filterText) ||
            filesData[key].year.toString().toLowerCase().includes(filterText) ||
            filesData[key].url.toString().toLowerCase().includes(filterText)
          ) {
            arr.push(filesData[key]);
          }
        }
        return arr;
      } else {
        for (const key in filesData) {
          arr.push(filesData[key]);
        }
        return arr;
      }
    } else {
      if (abstracts) {
        if (filterText) {
          for (const key in abstracts) {
            if (
              abstracts[key].title.toString().toLowerCase().includes(filterText)
            ) {
              arr.push(abstracts[key]);
            }
          }
          return arr;
        } else {
          for (const key in abstracts) {
            arr.push(abstracts[key]);
          }
          return arr;
        }
      } else {
        return arr;
      }
    }
  };

  const getDescription = () => {
    if (value == "uploads") {
      if (selected.length > 0) {
        return "This will remove selected files from library.";
      } else {
        return "This will remove file from library.";
      }
    } else {
      if (selected.length > 0) {
        return "This will remove selected files from abstracts.";
      } else {
        return "This will remove file from abstracts.";
      }
    }
  };

  // const deleteAbstracts = async (refRemove) => {
  //   try {
  //     setLoading(true);

  //     if (selRow) {
  //       let keys = [];
  //       let refKeys = [];
  //       let isRef = false;
  //       if (refRemove.length > 0) {
  //         isRef = true;
  //       }

  //       let key = Object.keys(abstracts).find(
  //         (key) => abstracts[key].title === selRow.title
  //       );
  //       keys.push(key);

  //       refRemove.forEach((item) => {
  //         let key = Object.keys(abstracts).find(
  //           (key) => abstracts[key].title === item
  //         );
  //         refKeys.push(key);
  //       });
  //       absToggle();
  //       let res = await Repo.research({
  //         request: {
  //           method: "deleteProjectDocument",
  //           data: {
  //             paperId: keys,
  //             isAll: false,
  //             email: localStorage.getItem("email"),
  //             title: projectTitle,
  //             uncheck: true,
  //             deleteReferences: isRef,
  //             referenceToDelete: refKeys,
  //           },
  //         },
  //       });
  //       setLoading(false);
  //       setSelRow("");

  //       if (refRemove.length > 0) {
  //         dispatch(clearRefs());
  //       }
  //       if (res.data.response.data.deleteFile) {
  //         Toast("success", "Abstracts deleted successfully");
  //         getProject();
  //       } else {
  //         Toast("error", "Error");
  //       }
  //     } else {
  //       let keys = [];
  //       let refKeys = [];
  //       let isRef = false;
  //       if (refRemove.length > 0) {
  //         isRef = true;
  //       }

  //       selected.forEach((item) => {
  //         let key = Object.keys(abstracts).find(
  //           (key) => abstracts[key].title === item.title
  //         );
  //         keys.push(key);
  //       });

  //       refRemove.forEach((item) => {
  //         let key = Object.keys(abstracts).find(
  //           (key) => abstracts[key].title === item
  //         );
  //         refKeys.push(key);
  //       });
  //       let res = await Repo.research({
  //         request: {
  //           method: "deleteProjectDocument",
  //           headers: {},
  //           data: {
  //             paperId: keys,
  //             isAll: selected.length == filtered().length ? true : false,
  //             email: localStorage.getItem("email"),
  //             title: projectTitle,
  //             uncheck: true,
  //             deleteReferences: isRef,
  //             referenceToDelete: refKeys,
  //           },
  //         },
  //       });
  //       setLoading(false);
  //       absToggle();
  //       if (refRemove.length > 0) {
  //         dispatch(clearRefs());
  //       }
  //       setSelected([]);
  //       if (res.data.response.data.deleteFile) {
  //         Toast("success", "Files deleted successfully");
  //         getProject();
  //       } else {
  //         Toast("error", "Error");
  //       }
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     setLoading(false);
  //     Toast("error", "Error");
  //   }
  // };

  // const deleteAbstracts = async (refRemove) => {
  //   try {
  //     setLoading(true);

  //     let keys = [];
  //     let refKeys = [];
  //     let isRef = refRemove.length > 0;

  //     // Process refFormats to match abstracts keys only if isRef is true
  //     if (isRef) {
  //       const abstractKeys = Object.keys(abstracts);

  //       // Find keys in refFormats where Harvard value matches abstracts
  //       const referenceToDelete = Object.keys(refFormats).filter((refKey) => {
  //         const harvardValue = refFormats[refKey].harvard.trim(); // Ensure no leading/trailing spaces
  //         return abstractKeys.includes(harvardValue);
  //       });

  //       // Map Harvard values to refFormats keys
  //       refKeys = referenceToDelete
  //         .filter((refKey) => {
  //           const harvardValue = refFormats[refKey].harvard.trim(); // Ensure no leading/trailing spaces
  //           return abstractKeys.includes(harvardValue); // Ensure Harvard value exists in abstracts
  //         })
  //         .map((refKey) => refKey) // Map to refFormats keys
  //         .filter((key) => key !== undefined); // Ensure no undefined keys
  //     }

  //     // Process selected or specific abstracts
  //     if (selRow) {
  //       let key = Object.keys(abstracts).find(
  //         (key) => abstracts[key].title === selRow.title
  //       );
  //       if (key) keys.push(key); // Add key to keys array if it exists
  //       absToggle();
  //     } else {
  //       selected.forEach((item) => {
  //         let key = Object.keys(abstracts).find(
  //           (key) => abstracts[key].title === item.title
  //         );
  //         if (key) keys.push(key); // Add key to keys array if it exists
  //       });
  //       absToggle();
  //     }

  //     // Call the API to delete the documents
  //     let res = await Repo.research({
  //       request: {
  //         method: "deleteProjectDocument",
  //         data: {
  //           paperId: keys,
  //           isAll: selected.length === filtered().length ? true : false,
  //           email: localStorage.getItem("email"),
  //           title: projectTitle,
  //           uncheck: true,
  //           deleteReferences: isRef,
  //           referenceToDelete: isRef ? refKeys : [], // Ensure refKeys is correctly passed
  //         },
  //       },
  //     });

  //     setLoading(false);
  //     if (refRemove.length > 0) {
  //       dispatch(clearRefs());
  //     }
  //     if (res.data.response.data.deleteFile) {
  //       Toast("success", "Files deleted successfully");
  //       getProject();
  //     } else {
  //       Toast("error", "Error");
  //     }

  //     setSelRow("");
  //     setSelected([]);
  //   } catch (err) {
  //     console.log(err);
  //     setLoading(false);
  //     Toast("error", "Error");
  //   }
  // };

  const deleteAbstracts = async (refRemove) => {
    try {
      setLoading(true);

      let keys = [];
      let refKeys = [];
      let isRef = refRemove.length > 0;

      // Process selected or specific abstracts
      if (selRow) {
        let key = Object.keys(abstracts).find(
          (key) => abstracts[key].title === selRow.title
        );
        if (key) keys.push(key); // Add key to keys array if it exists
        absToggle();
      } else {
        selected.forEach((item) => {
          let key = Object.keys(abstracts).find(
            (key) => abstracts[key].title === item.title
          );
          if (key) keys.push(key); // Add key to keys array if it exists
        });
        absToggle();
      }

      // Process refFormats to match abstracts keys only if isRef is true
      if (isRef) {
        refKeys = Object.keys(refFormats).filter((refKey) => {
          const harvardValue = refFormats[refKey].harvard.trim(); // Ensure no leading/trailing spaces
          return keys.includes(harvardValue); // Check if the Harvard value exists in keys
        });
      }
      //   // Map Harvard values to refFormats keys
      //   refKeys = referenceToDelete
      //     .filter((refKey) => {
      //       const harvardValue = refFormats[refKey].harvard.trim(); // Ensure no leading/trailing spaces
      //       return keys.includes(harvardValue); // Ensure Harvard value exists in keys
      //     })
      //     .map((refKey) => refFormats[refKey].harvard) // Map to Harvard values for consistency
      //     .filter((harvardValue) => harvardValue !== undefined); // Ensure no undefined values
      // }

      // Call the API to delete the documents
      let res = await Repo.research({
        request: {
          method: "deleteProjectDocument",
          data: {
            paperId: keys,
            isAll: selected.length === filtered().length ? true : false,
            email: localStorage.getItem("email"),
            title: projectTitle,
            uncheck: true,
            deleteReferences: isRef,
            // referenceToDelete: isRef ? refKeys : [], // Ensure refKeys is correctly passed
            referenceToDelete: isRef ? keys : [],
          },
        },
      });
      dispatch(getRefs(localStorage.getItem("projectId")));

      setLoading(false);
      if (refRemove.length > 0) {
        dispatch(clearRefs());
      }
      if (res.data.response.data.deleteFile) {
        Toast("success", "Files deleted successfully");
        getProject();
      } else {
        Toast("error", "Error");
      }

      setSelRow("");
      setSelected([]);
    } catch (err) {
      console.log(err);
      setLoading(false);
      Toast("error", "Error");
    }
  };

  const handleRemoveAll = () => {
    if (value == "uploads") {
      remToggle();
    } else if (value == "abstract") {
      absToggle();
    }
  };

  const handleInsights = () => {
    if (!filesData) {
      Toast("info", "Kindly upload papers");
      return;
    }
    if (selected.length === 0) {
      Toast("info", "Kindly select at least one paper");
      return;
    }
    let checkPdf = selected.filter(
      (i) => i.pdfFile.match(/\.([^.]+)$/)[1] === "pdf"
    );
    if (checkPdf.length !== selected.length) {
      Toast("info", "Kindly select only PDF files for paper insights.");
      return;
    }

    let planName = user?.subscription?.planName;
    let uploadLimit = 0;

    if (planName === "free-plan") {
      uploadLimit = 2;
    } else if (planName === "standard") {
      uploadLimit = 3;
    } else if (planName === "pro") {
      uploadLimit = 3;
    }

    console.log("Upload Limit is", uploadLimit);

    if (selected.length > uploadLimit) {
      Toast("info", `You can select maximum of ${uploadLimit} files.`);
      return;
    }

    let found = selected.find((i) => i.pdfFile === searchedFiles[i.pdfFile]);

    if (found) {
      Toast("info", "Some papers are already insighted");
      return;
    } else {
      console.log("selected:", selected);
      Toast("info", "Papers selected for Insights");
      dispatch(addForInsights({ title: projectTitle, selected }));

      let url = projectTitle.toString().toLowerCase().replace(/\s+/g, "-");
      navigate(`/projects/${url}/papers-insights`, { state: { selected } });
    }
  };

  // const handleInsights = () => {
  //   if (!filesData) {
  //     Toast("info", "Kindly upload papers");
  //     return;
  //   }
  //   if (selected.length == 0) {
  //     Toast("info", "Kindly select at least one paper");
  //     return;
  //   }
  //   let checkPdf = selected.filter(
  //     (i) => i.pdfFile.match(/\.([^.]+)$/)[1] == "pdf"
  //   );
  //   if (checkPdf.length !== selected.length) {
  //     Toast("info", "Kindly select only PDF files for paper insights.");
  //     return;
  //   }

  //   let uploadLimit = user?.subscription["paper-insights"] || 0;
  //   console.log("Upload Limit is", uploadLimit);
  //   let paperInsight = user?.subscription;
  //   console.log("Paper Insight value is", paperInsight);
  //   if (uploadLimit > 0 && selected.length > uploadLimit) {
  //     Toast("info", `Your paper insights limit is ${uploadLimit}`);
  //     return;
  //   }
  //   // else if (uploadLimit < 1) {
  //   //   Toast(
  //   //     "info",
  //   //     "Paper Insights limit reached. Please upgrade your subscription for more."
  //   //   );
  //   //   return;
  //   // }

  //   let found = selected.find((i) => i.pdfFile == searchedFiles[i.pdfFile]);

  //   if (found) {
  //     Toast("info", "Some papers are already insighted");
  //     return;
  //   } else {
  //     console.log("selected:", selected);
  //     Toast("info", "Papers selected for Insights");
  //     dispatch(addForInsights({ title: projectTitle, selected }));
  //     // dispatch(handleSelectedCom("papers-insights"));
  //     // dispatch(handleIsInsight(true));

  //     // localStorage.setItem("selectedPapers", JSON.stringify(selected));
  //     let url = "";
  //     url = projectTitle.toString().toLowerCase().replace(/\s+/g, "-");
  //     // navigate(`/projects/${url}/papers-insights`);
  //     // In Library component where you navigate to Paper Insights
  //     // navigate(`/projects/${url}/papers-insights`, {
  //     //   state: { selectedPaper: selected },
  //     // });

  //     navigate(`/projects/${url}/papers-insights`, { state: { selected } });
  //   }
  // };
  // const saveRefs = async () => {
  //   try {
  //     let req = {
  //       researchProjectId: localStorage.getItem("projectId"),
  //     };
  //     if (ref.pdfFile) {
  //       req.s3Files = [ref.pdfFile];
  //     }

  //     req.s3FileReferences = {
  //       [ref.pdfFile]: ref?.citations,
  //     };

  //     setRefLoading(true);
  //     await saveResearchReferences(req);
  //     dispatch(clearRefs());
  //     getFiles();
  //     setRefLoading(false);
  //     setModal(false);
  //   } catch (err) {
  //     getFiles();
  //     Toast("error", "Error");
  //   }
  // };
  // const toggle = () => {
  //   if (!paperLoading) {
  //     setModal((prev) => !prev);
  //     getFiles();
  //   }
  // };
  // const fetchRefs = async (file) => {
  //   const info = {
  //     getCitationsAgainstMultiplePapers: true,
  //     email: localStorage.getItem("email"),
  //     title: localStorage.getItem("projectTitle"),
  //     token: localStorage.getItem("token") || "",
  //     s3KeysArr: [file],
  //   };
  //   setFetchRefsLoadingMap((prev) => ({
  //     ...prev,
  //     [file]: true,
  //   }));
  //   const data = await fetchResearchReferences(info);
  //   console.log("data:", data);
  //   if (data) {
  //     setRef(data.data.responseArr);
  //     setModal(true);
  //     setFetchRefsLoadingMap((prev) => ({
  //       ...prev,
  //       [file]: false,
  //     }));
  //   }
  // };

  const subHeaderComponentMemo = () => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          flexWrap: "wrap",
          padding: "4px",
        }}
      >
        {viewLoading && (
          <Box>
            <TailSpin
              height="20px"
              width="20px"
              color="#353535"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </Box>
        )}
        <Typography
          sx={{
            fontFamily: "Raleway",
            fontSize: "12px",
            fontWeight: 400,
            padding: "4px",
            textAlign: "left",
          }}
        >
          Supported file format : PDF
        </Typography>

        {selected.length > 0 && (
          <Tooltip title="Delete Selected" placement="top">
            <DeleteForeverIcon
              sx={{ color: "#EB5757", cursor: "pointer", fontSize: "18px" }}
              onClick={handleRemoveAll}
            />
          </Tooltip>
        )}
        {value == "uploads" && (
          <Btn
            size="large"
            onClick={handleInsights}
            sx={{
              height: "32px !important",
              color: "#455CCB !important",
            }}
          >
            <img
              style={{ height: "15px", marginRight: "8px" }}
              src={RefsIcon}
              alt=""
            />
            Add To Paper Insights
          </Btn>
        )}
        {value == "uploads" && <UploadFile onSuccess={getFiles} />}
        {value == "uploads" &&
          (zoteroLoading ? (
            <Stack
              direction={"row"}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              spacing={1}
            >
              <TailSpin
                height="20px"
                width="20px"
                color="#353535"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
              <Typography fontSize={"10px"}>Please Wait</Typography>
            </Stack>
          ) : (
            <Btn
              size="small"
              onClick={zoToggle}
              sx={{ height: "32px !important" }}
            >
              <img style={{ marginRight: "8px" }} src={ZoterIcon} alt="" />
              Import from Zotero
            </Btn>
          ))}
        {value == "uploads" &&
          (mendelyLoading ? (
            <Stack
              direction={"row"}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              spacing={1}
            >
              <TailSpin
                height="20px"
                width="20px"
                color="#353535"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
              <Typography fontSize={"10px"}>Please Wait</Typography>
            </Stack>
          ) : (
            <Btn
              size="small"
              onClick={mendToggle}
              sx={{
                height: "32px !important",
              }}
            >
              <img style={{ marginRight: "8px" }} src={MendeleyIcon} alt="" />
              Import from Mendeley
            </Btn>
          ))}
        <TextField
          size="small"
          variant="outlined"
          placeholder="Search files ..."
          value={filterText}
          onChange={handleInput}
          InputProps={{
            style: {
              fontFamily: "Raleway",
              fontWeight: 400,
              fontSize: "12px",
              color: "#BDBDBD",
              borderRadius: "8px",
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ fontSize: "16px", color: "#BDBDBD" }} />
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiInputBase-input::placeholder": {
              color: "#2e2e2e", // Darker placeholder color
              fontWeight: "550",
            },
          }}
        />
      </Box>
    );
  };

  return (
    <Box sx={{ p: { xs: "8px", md: "8px 32px" } }}>
      <Stack direction="row" alignItems="center" spacing={0.8}>
        <Text sx={{ fontWeight: 600 }}>Display :</Text>
        <FormControl>
          <RadioGroup row value={value} onChange={handleRadio}>
            <FormControlLabel
              value="uploads"
              labelPlacement="start"
              control={
                <Radio
                  size="small"
                  disableRipple
                  sx={{
                    "&.Mui-checked": {
                      background: "linear-gradient(90deg, #5671F1, #17CEAD)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      p: "0px 0 0 4px",
                    },
                  }}
                />
              }
              label={<Text>My Uploads</Text>}
            />
            <FormControlLabel
              value="abstract"
              labelPlacement="start"
              control={
                <Radio
                  disableRipple
                  size="small"
                  sx={{
                    "&.Mui-checked": {
                      background: "linear-gradient(180deg, #5671F1, #17CEAD)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      p: "0px 0 0 4px",
                    },
                  }}
                />
              }
              label={<Text>Abstract Saved</Text>}
            />
          </RadioGroup>
        </FormControl>
      </Stack>

      {loading ? (
        <Box sx={{ mt: 5 }}>
          <TailSpinLoader />
        </Box>
      ) : (
        <>
          {/* <Box sx={{ maxHeight: "85vh", overflowY: "auto" }}> */}
          <Box sx={{ maxHeight: "88vh" }}>
            {/* <div style={{ height: "500px" }}> */}
            <DataTable
              columns={value == "uploads" ? columns : absColumns}
              data={filtered()}
              // customStyles={customStyles}
              customStyles={{
                ...customStyles,
                table: {
                  ...customStyles.table,
                  style: { ...customStyles.table.style, height: tableHeight },
                },
                subHeader: {
                  style: {
                    padding: 0,
                  },
                },
              }}
              selectableRows
              onSelectedRowsChange={rowSelection}
              clearSelectedRows={toggledClearRows}
              pagination
              subHeader
              subHeaderComponent={subHeaderComponentMemo()}
              noDataComponent={customNoDataComponent(
                (zoteroLoading || mendelyLoading || paperLoading) &&
                  filtered().length == 0
                  ? true
                  : false
              )}
              persistTableHead
            />
            {/* </div> */}
          </Box>
        </>
      )}
      {(zoteroModal || zoteroKey) && (
        <ZoteroDialogue
          onClose={closeToggle}
          id={projectId}
          getProject={getFiles}
          zKey={zoteroKey || null}
          open={zoteroModal}
        />
      )}
      {mendModal && (
        <MendeleyDialogue
          onClose={mendToggle}
          id={projectId}
          getProject={getFiles}
        />
      )}
      {/* {remModal && (
        <ConfirmationDialog
          text={getDescription()}
          onSuccess={deleteFiles}
          onClose={remToggle}
          refs={selRow ? [{ ...selRow }] : selected}
        />
      )} */}
      {remModal && (
        <ConfirmationDialog
          text={getDescription()}
          onSuccess={(selected, includeReferences) =>
            deleteFiles(selected, includeReferences)
          }
          onClose={remToggle}
          refs={selRow ? [{ ...selRow }] : selected}
        />
      )}
      {imageDialogue && imageUrl && (
        <ImageDialogue
          handleClose={handleClose}
          open={imageDialogue}
          imageUrl={imageUrl}
        />
      )}
      {/* {absModal && (
        <ConfirmationDialog
          text={getDescription()}
          onSuccess={deleteAbstracts}
          onClose={absToggle}
          refs={selRow ? [{ ...selRow }] : selected}
        />
      )} */}
      {absModal && (
        <ConfirmationDialogAbstracts
          text={getDescription()}
          onSuccess={(refRemove) => deleteAbstracts(refRemove)}
          onClose={absToggle}
          refs={selRow ? [{ ...selRow }] : selected}
        />
      )}

      {value == "uploads" ? (
        <UploadPdfDialogue
          handleClose={handleClose}
          open={open}
          pdfFile={pdfFile}
        />
      ) : (
        <AbstractDialogue
          AbstractData={AbstractData}
          handleCloseSecondDialogue={handleCloseSecondDialogue}
          openSecondDialogue={openSecondDialogue}
        />
      )}
      {/* {modal && (
        <SaveReferenceDialogue
          open={modal}
          handleClose={() => setModal(false)}
          saveRefs={saveRefs}
          ref={ref}
          setRef={setRef}
          refLoading={refLoading}
          toggle={toggle}
        />
      )} */}
    </Box>
  );
}

export default Library;
