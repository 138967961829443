import { Box, Stack, Typography, styled } from "@mui/material";
import React from "react";
// import universityHeroIconNew from "../../../assets/images/universityHeroIconNew.png";
import universityHeroIconNew from "../../../assets/images/universityeditormockup.png";
import bookStack from "../../../assets/images/bookStack.png";
import ContactUsSection from "./ContactUsSection";
const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "Righteous",
  fontWeight: 400,
  fontSize: "40px",
  // color: "linear-gradient(90deg, #FFC593 0%, #BC7198 100%)",
  background: "linear-gradient(90deg, #5671F1, #17CEAD)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  textAlign: "center",
  //   marginBottom: "64px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "25px",
  },
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Righteous",
  fontWeight: 400,
  color: "#353535",
  textAlign: "center",
  fontSize: "34px",
  [theme.breakpoints.down("md")]: {
    fontSize: "20px",
    textAlign: "center",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "18px",
  },
}));
const HeroSection = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        mt: 3,
      }}
    >
      <img
        src={universityHeroIconNew}
        alt="Hero Icon"
        width={400}
        height={270}
      />
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        mt={4}
        spacing={2}
      >
        {/* <img src={bookStack} alt="bookIcon" width={50} height={50} /> */}
        <Title
          sx={
            {
              // marginLeft: "30px !important",
            }
          }
        >
          ResearchPal
        </Title>
      </Stack>
      <Box
        sx={{
          maxWidth: "600px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
        }}
      >
        <Typography
          fontFamily={"Raleway"}
          textAlign={"center"}
          color={"#14224B"}
          fontWeight={600}
        >
          One tool for your students, researchers and faculty to access all the
          latest AI models, optimised for learning, writing and research.
        </Typography>
      </Box>
      <ContactUsSection />
    </Box>
  );
};

export default HeroSection;
