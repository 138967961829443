import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
} from "@mui/material";
import { Button, CircularProgress, Container } from "@mui/material";
import { Grid, InputAdornment, OutlinedInput, Stack } from "@mui/material";
import { Box, Tooltip, Typography, styled } from "@mui/material";
import ProjectIcon from "../../assets/images/NewImages/ProjectIcon.png";
import folder from "../../assets/images/NewImages/folder.png";
import LoupeIcon from "@mui/icons-material/Loupe";
import ChatIcon from "@mui/icons-material/Chat";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  deleteResearch,
  exchangeAuthorizationCode,
  getNewResearch,
  saveFeed,
  saveResearch,
} from "../../apiservice";
import moment from "moment/moment";
import { json, useLocation, useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import Alert from "../../components/Dialogue";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { toast } from "react-toastify";
import Alerts from "../../components/Alert";
import { Cancel, Delete } from "../style";
import LoopIcon from "@mui/icons-material/Loop";
import { Ratings } from "../../utils/Ratings";
import { Submit } from "../../assets/svg/LoopLogo";
import AdminHeader from "../../layout/admin header";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { useDispatch, useSelector } from "react-redux";
import { clearRefs } from "../references/refsSlice";
import IntroductoryDialogue from "../authentication/login/IntroductoryDialogue";
import Repo from "../../repo/Repo";
import { getUser, userAdd } from "../Admin/userSlice";
import Toast from "../../components/Toast";
import { CreateBtn, CardBox, Title } from "./styles";
import DomainDialogue from "./DomainDialogue";

import { selectProject } from "./projectSlice";
import ReactGA from "../../Analytics";
import Joyride from "react-joyride";
import { on } from "process";
import { OnBoardingSteps } from "../../utils/OnBoardingSteps";
import { JoyrideOptions } from "../../utils/JoyrideOptions";
import {
  clearLastSearchPapersRoute,
  setLastSearchPapersRoute,
} from "../papers/routeSlice";
import {
  clearSemanticPaperQuery,
  clearSemanticPapers,
  clearOffsets,
  clearSemFilters,
  setSemanticLoading,
  clearItems,
  clearSemanticStatus,
  clearToggleDropdown,
} from "../papers/semanticPaperSlice";
import {
  clearPapers,
  clearPaperQuery,
  resetPageNo,
  clearDomain,
} from "../papers/paperSlice";

import {
  clearLitDomain,
  clearLitReview,
  clearSelectedFormat,
  clearValue,
  clearLitLoading,
} from "../literature review/reviewSlice";
import { persistor } from "../../store";
import hero_bg from "../../assets/images/NewImages/hero_bg.png";
import { Check, Verified } from "@mui/icons-material";
// export const purgeSemanticPaperSlice = () => {
//   persistor.purge("persist:semanticPaper");
// };

// import { addInsightsProject } from "../paper insights/insightsSlice";
import {
  clearColumnLoading,
  clearInsightFormat,
  clearInsightLoading,
  clearSelectedFiles,
} from "../paper insights/insightsSlice";

function ProjectsList() {
  let feedback = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    projectsData: [],
    loader: false,
    open: false,
    title: "",
    popUp: false,
    alert: false,
    selectedItem: {},
    ratings: 0,
  });
  const [showFeedback, setShowFeedback] = React.useState(false);
  const queryString = window.location.search;
  const [open, setOpen] = useState(false);
  const [domainDialogue, setDomainDialogue] = useState(false);
  const { user, loading: userLoading } = useSelector((state) => state.user);
  const location = useLocation();
  console.log("location:", location);
  const refresh = localStorage.getItem("refresh");
  const [allowDialogue, setAllowDialogue] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  console.log(
    "Value of Domain Access is",
    user?.domainAccess,
    " and googleLogin is",
    user.googleLogin
  );
  console.log("Value of onboarding", user?.onboarding);
  const [run, setRun] = useState(false);
  const prTitle = localStorage.getItem("projectTitle");
  useEffect(() => {
    if (user?.domainAccess === false && user.googleLogin === true) {
      setDomainDialogue(true);
      //open boarding dialogue when domain dialogue is closed
    } else if (user?.onboarding === true) {
      // const openModal = () => {
      // setOpen(true);
      setRun(true);
      // subscirbe();
      // };

      // setTimeout(openModal, 3000);
    } else if (
      !domainDialogue &&
      user?.domainAccess === true &&
      user?.onboarding === true
    ) {
      setOpen(true);
    }
  }, [user]);
  // Dispatch getUser action to fetch user data once on mount
  // useEffect(() => {
  //   if (!user) {
  //     // Only dispatch if user is not already fetched
  //     dispatch(getUser());
  //   }
  // }, [dispatch, user]);

  // // Handle domain access and onboarding logic based on user data
  // useEffect(() => {
  //   console.log("User data updated:", user); // Log user data changes
  //   if (!userLoading && user) {
  //     if (user?.domainAccess === false && user.googleLogin === true) {
  //       setDomainDialogue(true);
  //     } else if (user?.onboarding === true) {
  //       setRun(true);
  //     } else if (
  //       !domainDialogue &&
  //       user?.domainAccess === true &&
  //       user?.onboarding === true
  //     ) {
  //       setOpen(true);
  //     }
  //   }
  // }, [user, userLoading, domainDialogue]);

  // useEffect(() => {
  //   getUsers();
  // }, []);

  // const getUsers = async () => {
  //   setLoading(true);
  //   try {
  //     let { data } = await Repo.user({
  //       request: {
  //         method: "getUser",
  //         data: {
  //           _id: localStorage.getItem("email"),
  //           token: localStorage.getItem("token") || "",
  //         },
  //       },
  //     });
  //     setLoading(false);
  //   } catch (err) {
  //     console.log("err:", err);
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    //convert unix timestamp to date
    if (user?.nextPaymentDate) {
      const unixTimestamp = user?.nextPaymentDate;
      const milliseconds = unixTimestamp * 1000; // 1575909015000
      const dateObject = new Date(milliseconds);
      const currentDate = new Date();

      if (dateObject < currentDate && user?.cancelSubscription === true) {
        handleUsageCancellation();
        dispatch(getUser());
      }
    }
  }, [user]);

  const handleUsageCancellation = async () => {
    let info = {
      email: localStorage.getItem("email"),
    };
    await Repo.subscribe({
      request: {
        method: "makeLimitationsZero",
        data: info,
      },
    });
  };
  useEffect(() => {
    if (refresh === "true") {
      window.location.reload();
      localStorage.setItem("refresh", false);
    }
  }, [refresh]);

  useEffect(() => {
    dispatch(clearRefs());
    getProjects();
    //refresh the page

    if (queryString) {
      const urlParams = new URLSearchParams(queryString);
      const code = urlParams.get("code");
      exchange(code);
    }
    const keyDownHandler = (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        document.getElementById("btn").click();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  const exchange = async (code) => {
    let MendeleyData = {
      authorizationCode: code,
      email: localStorage.getItem("email"),
    };
    let resp = await exchangeAuthorizationCode(MendeleyData);
    if (resp) {
      localStorage.setItem("access", resp.access_token);
    }
  };
  const handleClear = () => {
    setState((prev) => ({ ...prev, title: "" }));
  };

  const getProjects = async () => {
    setState((prev) => ({ ...prev, loader: true }));
    let info = {
      email: localStorage.getItem("email"),
    };

    // try {
    // let res = await getResearch(info);
    let res = await getNewResearch(info);
    //set some delay to show project late

    setState((prev) => ({
      ...prev,
      projectsData: res?.researchProjects || [],
      loader: false,
    }));
    //   await getUsers();
    // } catch (err) {
    //   console.log("Error in getProjects:", err);
    //   setState((prev) => ({ ...prev, loader: false }));
    // }
  };
  const getRatings = (rt) => {
    setState({ ...state, ratings: rt });
  };
  const calculateDate = (seconds) => {
    const date = moment.unix(seconds);
    let formated = date.format("DD/MM/YYYY");
    return formated;
  };
  const handleLocation = (item) => {
    let url = "";
    url = item?.title.toString().toLowerCase().replace(/\s+/g, "-");
    localStorage.setItem("projectId", item._id);
    localStorage.setItem("projectTitle", item.title);
    dispatch(clearSemanticPapers());
    dispatch(setLastSearchPapersRoute(`/projects/${url}/search-papers`));
    localStorage.setItem("selectedItem", JSON.stringify([]));
    localStorage.setItem("navigationHistory", JSON.stringify([]));
    dispatch(clearSemanticPaperQuery());
    dispatch(clearPapers());
    dispatch(clearPaperQuery());
    dispatch(clearSemFilters());
    dispatch(clearOffsets());
    dispatch(resetPageNo());
    dispatch(clearDomain());
    dispatch(clearItems());
    dispatch(clearSemanticStatus());
    dispatch(clearToggleDropdown());
    dispatch(clearLitDomain());
    // dispatch(clearLitReview(prTitle));
    dispatch(clearLitLoading());
    dispatch(clearSelectedFormat());
    dispatch(clearValue());
    dispatch(setSemanticLoading(false));
    dispatch(clearSelectedFiles());
    // purgeSemanticPaperSlice();
    dispatch(clearColumnLoading());
    dispatch(clearInsightLoading());
    dispatch(clearInsightFormat());

    // dispatch(selectProject(item));
    // dispatch(addInsightsProject(item.title));
    navigate(`/projects/${url}/document`);
  };
  const addToggle = () => {
    setState((prev) => ({ ...prev, open: !prev.open }));
  };
  const handleChange = (e) => {
    let { value } = e.target;
    // setState((prev) => ({ ...prev, title: value }));
    if (value.length <= 60) {
      if (value.includes("\\") || value.includes("/")) {
        setErrorMessage(
          `Special character "${value[value.length - 1]}" not allowed.`
        );
      } else {
        setErrorMessage("");
        setState((prev) => ({ ...prev, title: value }));
      }
    }
  };
  const saveProject = async () => {
    try {
      setLoading(true);

      if (!state.title.trim()) {
        Toast("info", "Enter Title");
        return;
      }
      let data = {
        email: localStorage.getItem("email"),
        title: state.title.trim(),
      };
      let resp = await saveResearch(data);
      setLoading(false);
      if (resp.data.researchProjectId) {
        toast.success("Project created successfully!");
        addToggle();
        getProjects();
      } else {
        toast.error(resp.status.statusDescription);
        console.log(resp);
      }
    } catch (err) {
      console.log("err:", err);
      setLoading(false);
    }
  };
  const handleDiv = () => {
    if (!localStorage.getItem("email")) {
      setState((prev) => ({ ...prev, popUp: !prev.popUp }));
    }
  };

  const openDialogue = async (e, item) => {
    e.stopPropagation();
    setState((prev) => ({ ...prev, alert: !prev.alert, selectedItem: item }));
  };
  const deleteProject = async () => {
    setState((prev) => ({ ...prev, loader: true }));
    let info = {
      _id: state.selectedItem._id,
    };
    let res = await deleteResearch(info);
    if (res.researchProjectId) {
      getProjects();
      toast.error("Project deleted successfully");
      setState((prev) => ({ ...prev, loader: false, alert: !prev.alert }));
    }
  };
  const handleFeedback = () => {
    setShowFeedback(!showFeedback);
  };
  const SubmitFeedback = async () => {
    if (!state.ratings) {
      toast.error("Please provide a rating.");
      return;
    }

    if (!feedback.current.value.trim()) {
      toast.error("Please provide feedback.");
      return;
    }

    let data = {
      email: localStorage.getItem("email"),
      feedback: {
        rating: state.ratings,
        date: moment().format("DD-MM-YYYY"),
        message: feedback.current.value,
      },
    };
    let res = await saveFeed(data);
    if (res.feedbackId) {
      setShowFeedback(!showFeedback);
      toast.success("Feedback saved successfully");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDomainDialogue = () => {
    setDomainDialogue(false);
  };
  const handleFinish = async () => {
    const userEmail = localStorage.getItem("email");

    handleClose();
    await Repo.user({
      request: {
        method: "updateUser",
        data: {
          _id: userEmail,
          onboarding: false,
        },
      },
    });
    dispatch(userAdd({ ...user, onboarding: false }));
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${hero_bg})`,
        backgroundSize: "cover",
      }}
    >
      <Box
        sx={{
          zIndex: 1,
          // mt: 1.6,
          mx: 3.2,
          p: 2,
          position: "sticky",
          right: 5,
          left: 5,
          top: 1,
          backgroundColor: "rgba(254, 254, 254, 0.32)",
          backdropFilter: "blur(12.17898178100586px)",
        }}
      >
        <AdminHeader />
      </Box>
      <Container>
        <Stack
          alignItems="center"
          my={3.6}
          direction="row"
          spacing={1.6}
          justifyContent={{ xs: "center", sm: "unset" }}
        >
          <img style={{ height: "25px" }} src={ProjectIcon} alt="" />
          <Title>Projects</Title>
          <CreateBtn
            onClick={addToggle}
            disabled={!localStorage.getItem("email")}
            id="create-project"
          >
            <LoupeIcon sx={{ color: "#14224B" }} id="create-project" />
          </CreateBtn>
          {/* <Joyride
            steps={OnBoardingSteps}
            hideCloseButton
            scrollToFirstStep
            // showProgress
            disableOverlayClose
            styles={JoyrideOptions}
            run={run}
            callback={(data) => {
              if (data.status === "finished") {
                setRun(false);
                handleFinish();
              }
            }}
          /> */}
        </Stack>
        <Stack
          zIndex={0}
          spacing={5}
          sx={{ mt: 5, minHeight: "80vh" }}
          onClick={handleDiv}
        >
          {state.popUp && <Alerts open={state.popUp} />}

          {state.loader ? (
            <Stack justifyContent="center" alignItems="center">
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#353535"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </Stack>
          ) : (
            <Grid container spacing={3}>
              {state?.projectsData?.map((item, id) => (
                <Grid key={id} item md={2.4} sm={6} xs={12}>
                  <CardBox
                    onClick={() => handleLocation(item)}
                    id="open-project"
                  >
                    <Stack alignItems="center" justifyContent="center">
                      <img style={{ height: "35px" }} src={folder} alt="" />
                    </Stack>
                    <Stack textAlign={{ xs: "center", md: "unset" }}>
                      <Title sx={{ fontSize: "14px" }}>
                        {item.title.length > 20
                          ? item.title.slice(0, 45) + "..."
                          : item.title}
                      </Title>
                      <Title sx={{ fontSize: "10px", fontWeight: 500 }}>
                        Created :{calculateDate(item.created_at)}
                      </Title>
                    </Stack>
                    <Stack
                      direction="row"
                      width="100%"
                      justifyContent="space-between"
                    >
                      <Title sx={{ fontSize: "14px", fontWeight: 600 }}>
                        {id < 9 && "0"}
                        {id + 1}
                      </Title>
                      <DeleteForeverOutlinedIcon
                        sx={{
                          cursor: "pointer",
                          fontSize: "25px",
                          color: "#CB4C4C99",
                        }}
                        onClick={(e) => openDialogue(e, item)}
                      />
                    </Stack>
                  </CardBox>
                </Grid>
              ))}
              {state?.projectsData?.length == 0 && (
                <Stack justifyContent="center" alignItems="center">
                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: 500,
                      color: "#928B82",
                      marginLeft: 3,
                    }}
                  >
                    Create your first project
                  </Typography>
                </Stack>
              )}
            </Grid>
          )}
          {showFeedback ? (
            <Box className="feedback2 grid place-items-center ">
              <button style={{ color: "#000" }} onClick={handleFeedback}>
                <CancelIcon />
              </button>
              <div className="flex ">
                <div className="flex ">
                  <div>
                    <div className="grid place-items-center mb-3">
                      <h1 style={{ color: "#14224B", fontWeight: "bold" }}>
                        Give Your Feedback
                      </h1>
                      <Ratings getRatings={(rt) => getRatings(rt)} />
                    </div>
                    <textarea
                      style={{
                        width: "300px",
                        border: "1px solid #14224B",
                        borderRadius: "8px",
                      }}
                      className="px-2 mb-3  justify-center mb-1 text-sm text-black"
                      rows={5}
                      ref={feedback}
                      placeholder="Your feedback ..."
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        onClick={SubmitFeedback}
                        sx={{
                          background:
                            "linear-gradient(90deg, #83E1B4, #F0D795) !important",
                          color: "#14224B",
                          px: 4,
                        }}
                        startIcon={<Verified />}
                      >
                        Submit
                      </Button>
                    </Box>
                  </div>
                </div>
              </div>
            </Box>
          ) : (
            <Tooltip title="Share your feedback" placement="left">
              <IconButton
                // className="scroll-to-top-button"
                onClick={handleFeedback}
                sx={{
                  position: "fixed",
                  bottom: "5%",
                  right: "2%",
                  background: "#fff",
                  color: "#353535",
                  "&:hover": {
                    background: "#F3F3F3",
                  },
                }}
              >
                <ChatIcon />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </Container>
      {/* <AdminFooter /> */}

      <Dialog open={state.open} onClose={addToggle} fullWidth maxWidth="xs">
        <DialogContent>
          <Stack spacing={3}>
            <Title sx={{ textAlign: "center" }}>Create new project</Title>
            <Title
              sx={{ textAlign: "center", fontWeight: 500, fontSize: "12px" }}
            >
              (Write 60 characters max)
            </Title>
            <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
              <OutlinedInput
                sx={{
                  background: "#F4F4F4",
                  "& fieldset": {
                    border: "none",
                  },
                }}
                autoFocus
                size="small"
                placeholder="Project title here...."
                id="outlined-adornment-weight"
                value={state.title}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <CancelOutlinedIcon
                      sx={{ fontSize: "16px", cursor: "pointer" }}
                      onClick={handleClear}
                    />
                  </InputAdornment>
                }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
              />
              {errorMessage && (
                <Typography color="error" variant="caption">
                  {errorMessage}
                </Typography>
              )}
            </FormControl>
            {loading && (
              <Stack alignItems="center" justifyContent="center">
                <CircularProgress sx={{ color: "#333333" }} size={32} />
              </Stack>
            )}
            {!loading && (
              <Stack
                direction="row"
                justifyContent="flex-end"
                spacing={1.6}
                width={"100%"}
              >
                <Button
                  fullWidth
                  variant="contained"
                  size="small"
                  sx={{
                    background: "#F3F3F3 !important",
                    color: "#353535",
                    textTransform: "none",
                  }}
                  onClick={addToggle}
                >
                  Cancel
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  id="btn"
                  size="small"
                  sx={{
                    background: "linear-gradient(90deg, #5671F1, #17CEAD)",
                    textTransform: "none",
                    "&:hover": {
                      background: "linear-gradient(90deg, #3950CE, #15C3A3)",
                    },
                  }}
                  onClick={saveProject}
                >
                  Create
                </Button>
              </Stack>
            )}
          </Stack>
        </DialogContent>
      </Dialog>

      <Alert
        open={state.alert}
        close={openDialogue}
        project="abc"
        content={
          <div>
            <Typography sx={{ fontSize: "16px" }}>
              Are you sure you want to delete this project? This will
              permanently delete all the content associated with this project
              including the research sources, citations and literature reviews.
            </Typography>
            <Stack justifyContent="end" direction="row" sx={{ mt: 3 }}>
              <Cancel size="small" onClick={openDialogue} sx={{ mx: 2 }}>
                Cancel
              </Cancel>
              <Delete
                size="small"
                disabled={state.loader}
                onClick={deleteProject}
              >
                {state.loader && <LoopIcon />} Delete
              </Delete>
            </Stack>
          </div>
        }
      />
      {open && (
        <IntroductoryDialogue openDialogue={open} onClose={handleFinish} />
      )}
      {domainDialogue && user?.domainAccess === false && (
        <DomainDialogue
          open={true}
          onClose={handleDomainDialogue}
          setDomainDialogue={setDomainDialogue}
        />
      )}
    </Box>
  );
}

export default ProjectsList;
