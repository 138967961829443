import axios from "axios";
import Repo from "../repo/Repo";
import { baseDomain } from "../repo/Repository";

export const saveFeedback = async (userData) => {
  let res = await axios.post(
    `https://veracious.herokuapp.com/direct_feedback`,
    {
      request: {
        method: "saveDirectFeedback",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};
export const cancelEmbed = async (userData) => {
  let res = await axios.post(`${baseDomain}/script`, userData);
  let json = res.data.response.data;
  return json;
};
export const saveEmbed = async (userData) => {
  let res = await axios.post(`${baseDomain}/script`, userData);
  let json = res.data.response.data;
  return json;
};
export const getFeedback = async (userData) => {
  let res = await axios.post(`${baseDomain}/feedback`, {
    request: {
      method: "getFeedbacks",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const getLitFeedback = async (userData) => {
  let res = await axios.post(`${baseDomain}/literature_ratings`, {
    request: {
      method: "getLiteratureRatings",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const getFeedbackEmails = async () => {
  let res = await axios.post(`${baseDomain}/feedback`, {
    request: {
      method: "getFeedbacksEmails",
    },
  });
  let json = res.data.response.data;
  return json;
};
export const getUser = async (userData) => {
  let { data } = await axios.post(`${baseDomain}/users`, {
    request: {
      method: "getUser",
      data: { ...userData, token: localStorage.getItem("token") || "" },
    },
  });
  return data;
};
export const getLatestActivity = async (arg) => {
  let res = await axios.post(`${baseDomain}/research-projects`, {
    request: {
      method: "getLatestActivityByEmail",
      data: {
        emails: arg,
      },
    },
  });
  let json = res.data.response.data;
  return json;
};
export const getAllUser = async (userData) => {
  let res = await axios.post(`${baseDomain}/users`, {
    request: {
      method: "getAllUsers",
    },
  });
  let json = res.data.response.data;
  return json;
};
export const updateUser = async ({ email, password }) => {
  let { data } = await Repo.user({
    request: {
      method: "updateUser",
      data: {
        _id: email,
        password: password,
        passwordChange: true,
      },
    },
  });
  return data;
};
export const disconnectZotero = async (userData) => {
  let { data } = await Repo.user({
    request: {
      method: "deleteZoteroKey",
      data: {
        _id: userData,
      },
    },
  });
  return data;
};
export const getAllFeedbacks = async (userData) => {
  let res = await axios.post(
    `https://veracious.herokuapp.com/direct_feedback`,
    {
      request: {
        method: "getAllDirectFeedbacks",
        data: userData,
      },
    }
  );
  let json = res.data.response.data;
  return json;
};

export const getHistory = async (userData) => {
  let res = await axios.post(`${baseDomain}/research-projects`, {
    request: {
      method: "getDocumentsHistory",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};

export const saveHistory = async (userData) => {
  let res = await axios.post(`${baseDomain}/research-projects`, {
    request: {
      method: "saveVersionDocument",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};

export const saveResearch = async (userData) => {
  let res = await axios.post(`${baseDomain}/research-projects`, {
    request: {
      method: "saveResearch",
      data: userData,
    },
  });
  let json = res.data.response;
  return json;
};
export const getResearch = async (userData) => {
  let res = await axios.post(`${baseDomain}/research-projects`, {
    request: {
      method: "getProjectsByEmail",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const getDocumentData = async (userData) => {
  let res = await axios.post(`${baseDomain}/research-projects`, {
    request: {
      method: "getDocumentData",
      data: userData,
    },
  });
  let json = res.data.response;
  return json;
};
export const getNewResearch = async (userData) => {
  let res = await axios.post(`${baseDomain}/research-projects`, {
    request: {
      method: "getProjectsByEmailNew",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const deleteResearch = async (userData) => {
  let res = await axios.post(`${baseDomain}/research-projects`, {
    request: {
      method: "deleteResearchProject",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const updateResearchProject = async (userData) => {
  let res = await axios.post(`${baseDomain}/research-projects`, {
    request: {
      method: "updateResearchProject",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const EditReferences = async (userData) => {
  let res = await axios.post(`${baseDomain}/research-projects`, {
    request: {
      method: "editResearchReferences",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const generateAbstracts = async (userData) => {
  let res = await axios.post(`${baseDomain}/script`, {
    request: {
      data: JSON.stringify(userData),
    },
  });
  let json = res.data.response.data;
  return json;
};
export const manualRefrence = async (userData) => {
  let res = await axios.post(`${baseDomain}/script`, {
    request: {
      method: "manualRefrence",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const sematicCitation = async (userData) => {
  let res = await axios.post(`${baseDomain}/script`, userData);

  return res;
};
export const referenceCitation = async (userData) => {
  let res = await axios.post(`${baseDomain}/script`, userData);

  return res;
};
export const askRearchPal = async (userData) => {
  let res = await axios.post(`${baseDomain}/script`, userData);
  return res;
};
export const citation = async (userData) => {
  let res = await axios.post(`${baseDomain}/script`, userData);
  return res;
};
export const tonesApi = async (data) => {
  let res = await axios.post(`${baseDomain}/script`, data);
  return res;
};
export const translateApi = async (data) => {
  let res = await axios.post(`${baseDomain}/script`, data);
  return res;
};
export const textTunnerApi = async (data) => {
  let res = await axios.post(`${baseDomain}/script`, data);
  return res;
};
export const saveAbstracts = async (userData) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  let res = await axios.post(`${baseDomain}/research-projects`, {
    request: {
      // method: "saveResearchAbstarct",
      method: "saveResearchAbstract",
      headers: myHeaders,
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const saveLiteratureRatings = async (userData) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  let res = await axios.post(`${baseDomain}/literature_ratings`, {
    request: {
      method: "saveLiteratureRatings",
      headers: myHeaders,
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const saveResearchReferences = async (userData) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  let res = await axios.post(`${baseDomain}/research-projects`, {
    request: {
      method: "saveResearchReferences",
      headers: myHeaders,
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const saveResearchSearchReference = async (userData) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  let res = await axios.post(`${baseDomain}/research-projects`, {
    request: {
      method: "retrieveReference",
      headers: myHeaders,
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};

export const saveReferenceNew = async (userData) => {
  let res = await axios.post(`${baseDomain}/script`, userData);
  return res;
};

export const getResearchReferences = async (userData) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  let res = await axios.post(`${baseDomain}/research-projects`, {
    request: {
      method: "getResearchReferences",
      headers: myHeaders,
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const fetchResearchReferences = async (userData) => {
  let res = await axios.post(`${baseDomain}/script`, userData);
  return res.data;
};
export const deleteProjectDocument = async (userData) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  let res = await axios.post(`${baseDomain}/research-projects`, {
    request: {
      method: "deleteProjectDocument",
      headers: myHeaders,
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const saveFeed = async (userData) => {
  let res = await axios.post(`${baseDomain}/feedback`, {
    request: {
      method: "saveFeedback",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};

export const sendAccessRequest = async (userData) => {
  let res = await axios.post(`${baseDomain}/email`, {
    request: {
      method: "sendAccessRequest",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const getDocumentsHistory = async (userData) => {
  let res = await axios.post(`${baseDomain}/research-projects`, {
    request: {
      method: "getDocumentsHistory",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const getAuthorizationURL = async ({ url }) => {
  let res = await axios.post(`${baseDomain}/zotero`, {
    request: {
      method: "getAuthorizationURL",
      data: {
        url: url,
      },
    },
  });
  let json = res.data.response.data;
  return json;
};
export const getMendeleyAuthorizationURL = async () => {
  let res = await axios.post(`${baseDomain}/mendeley`, {
    request: {
      method: "getAuthorizationURL",
    },
  });
  let json = res.data.response.data;
  return json;
};
export const fetchMendeleyPdf = async (url, email) => {
  let res = await axios.get(
    `${baseDomain}/fetchMendeleyPdf?pdfUrl=${url}&email=${email}`
  );
  let json = res.data.response.data;
  return json;
};
export const getAccessToken = async (userData) => {
  let res = await axios.post(`${baseDomain}/zotero`, {
    request: {
      method: "getAccessToken",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const exchangeAuthorizationCode = async (userData) => {
  let res = await axios.post(`${baseDomain}/mendeley`, {
    request: {
      method: "exchangeAuthorizationCode",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const getMendeleyFiles = async (userData) => {
  let res = await axios.post(`${baseDomain}/mendeley`, {
    request: {
      method: "getMendeleyFiles",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const saveMultipleFiles = async (userData) => {
  let res = await axios.post(`${baseDomain}/core`, {
    request: {
      method: "saveFiles",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const getFileContent = async (userData) => {
  let jsonData = {};
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var request = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(userData),
    redirect: "follow",
  };
  await fetch(`${baseDomain}/script`, request)
    .then((response) => response.text())
    .then((result) => {
      let parsed = JSON.parse(result);
      jsonData = parsed.data.sourceObj;
    });
  return jsonData;
};
export const deleteReferences = async (userData) => {
  let res = await axios.post(`${baseDomain}/research-projects`, {
    request: {
      method: "deleteReferences",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};
export const subscribePlan = async (userData) => {
  let res = await axios.post(`${baseDomain}/subscription`, {
    request: {
      method: "subscribePlan",
      data: userData,
    },
  });
  let json = res.data.response.data;
  return json;
};

export const getPaymentHistory = async (userData) => {
  let res = await axios.post(`${baseDomain}/subscription`, {
    request: {
      method: "getPaymentHistory",
      data: userData,
    },
  });
  let json = res.data;
  return json;
};

export const sendCancelSubscriptionMail = async (email, reason, comment) => {
  try {
    let res = await axios.post(`${baseDomain}/email`, {
      request: {
        method: "sendCancelSubscriptionMail",
        data: {
          to: email,
          reason: reason,
          comment: comment,
        },
      },
    });

    let json = res.data;
    return json;
  } catch (error) {
    console.error("Error sending cancellation email:", error);
    throw error;
  }
};
