import { Box, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ReactGA from "../../Analytics";
import AdminHeader from "../../layout/admin header";
import { getUser } from "../Admin/userSlice";
import Confirmation from "./Confirmation";
import Contact from "./Contact";
import NewPayment from "./NewPayment";
import UpdatePackage from "./UpdatePackage";
import NewCards from "./new cards";
import hero_bg from "../../assets/images/NewImages/hero_bg.png";
const RootStyle = styled(Box)({
  minHeight: "100%",
  marginTop: "20px",
});

function Subscription() {
  const urlSearchParams = new URLSearchParams(window.location.href);
  const session_id = urlSearchParams.get("session_id");
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [sessionId, setSessionId] = useState(session_id);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState({
    open: false,
    pay: false,
    plan: "",
    user: {},
    subscribed: false,
    billingPeriod: "yearly",
  });

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Subscription",
    });
  }, []);

  useEffect(() => {
    // get();

    if (!user) {
      dispatch(getUser());
    }
  }, []);

  const sessionToggle = () => {
    setSessionId(null);
  };

  const toggle = (arg, billingPeriod) => {
    let standard = arg;
    let billing = billingPeriod;
    console.log("billing:", billing);
    if (arg === "standard" || arg === "pro") {
      setState((prev) => ({
        ...prev,
        pay: !prev.pay,
        plan: standard,
        billingPeriod: billing,
      }));
    } else if (arg === "subscribed") {
      setState((prev) => ({
        ...prev,
        subscribed: !prev.subscribed,
        plan: "standard",
      }));
    } else {
      setState((prev) => ({ ...prev, open: !prev.open, plan: standard }));
    }
  };

  const close = () => {
    setState((prev) => ({ ...prev, pay: !prev.pay, plan: "" }));
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${hero_bg})`,
        backgroundSize: "cover",
      }}
    >
      <Box
        sx={{
          zIndex: 1,
          // mt: 1.6,
          mx: 3.2,
          p: 2,
          position: "sticky",
          right: 5,
          left: 5,
          top: 1,
          backgroundColor: "rgba(254, 254, 254, 0.32)",
          backdropFilter: "blur(12.17898178100586px)",
        }}
      >
        <AdminHeader />
      </Box>
      <RootStyle>
        <NewCards onSelect={toggle} />
      </RootStyle>
      {state.pay && (
        <NewPayment
          onClose={() => close()}
          plan={state.plan}
          billing={state.billingPeriod}
        />
      )}
      {sessionId && <Confirmation onClose={sessionToggle} id={sessionId} />}
      {state.open && <Contact open={state.open} onClose={toggle} />}
      {state.subscribed && (
        <UpdatePackage
          plan={state.plan}
          open={state.subscribed}
          onClose={() => toggle("subscribed")}
        />
      )}
    </Box>
  );
}

export default Subscription;
