import { Box, Typography, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  saveAbstracts,
  saveResearchSearchReference,
  saveReferenceNew,
} from "../../apiservice";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SemanticContent from "./SemanticContent";
import SemanticItemBox from "./SemanticItemBox";
import { addItems, addSemanticStatus } from "./semanticPaperSlice";
import { clearRefs, getRefs } from "../references/refsSlice";
import { RecomendationBtn, Title } from "./styles";
import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { sematicCitation } from "../../apiservice";
import { referenceCitation } from "../../apiservice";
import Toast from "../../components/Toast";
import TailSpinLoader from "../../components/TailSpinLoader";
import { TailSpin } from "react-loader-spinner";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { setLastSearchPapersRoute } from "./routeSlice";

export const Text = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 400,
  fontSize: "12px",
  marginTop: 5,
  marginRight: 3,
  color: "#353535CC",
});

const truncateText = (text, maxLength) => {
  if (!text) return ""; // Ensure that text is not null or undefined
  return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
};

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#353535",
    height: "4px",
  },
  justifyContent: "center",
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&.Mui-selected": {
      color: "#353535",
      fontWeight: theme.typography.fontWeightBold,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
    paddingLeft: "34px",
    paddingRight: "34px",
  })
);

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useSaveCurrentRoute = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      // Dispatch action to save the current route to Redux state when navigating away
      dispatch(setLastSearchPapersRoute(location.pathname));
    };
  }, [location, dispatch]);
};

const SemanticItems = () => {
  useSaveCurrentRoute();
  const [value, setValue] = useState(0);
  const [citation, setCitations] = useState([]);
  const [references, setReferences] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [citePageNo, setCitePageNo] = useState(1);
  const [selectedDoi, setSelectedDoi] = useState({});
  const [saveLoading, setSaveLoading] = useState(false);
  const { semanticSaveStatus, items } = useSelector(
    (state) => state.semanticPaper
  );
  const [refPageNo, setRefPageNo] = useState(1);
  const isSmall = useMediaQuery("(max-width:980px)");
  const dispatch = useDispatch();

  const location = useLocation();
  const { id } = useParams();
  console.log("id:", id);
  // const itemData = localStorage.getItem("items");
  const [item, setItem] = useState(items || null);
  console.log("item:", item);
  const [semanticLoading, setSemanticLoading] = useState(false);
  const [refLoading, setRefLoading] = useState(false);
  const [refLoadMore, setRefLoadMore] = useState(false);
  const [citeLoadMore, setCiteLoadMore] = useState(false);
  const [hasReference, setHasReference] = useState(true);
  const [hasCitation, setHasCitation] = useState(true);
  const [citationsNoLongerAvailable, setCitationsNoLongerAvailable] =
    useState(false);
  const [referencesNoLongerAvailable, setReferencesNoLongerAvailable] =
    useState(false);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(item.tldr)
      .then(() => {
        setTooltipOpen(true);
        setTimeout(() => setTooltipOpen(false), 1500); // Tooltip will be visible for 1.5 seconds
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  useEffect(() => {
    if (!item) {
      console.log("hellllo");
      // If item is not in state, try to retrieve it from local storage
      const storedItem = localStorage.getItem("selectedItem");
      if (storedItem) {
        const parsedItem = JSON.parse(storedItem);
        if (parsedItem.paperId === id) {
          setItem(parsedItem);
        }
      }
    }
  }, [id, item]);

  const semanticCall = async () => {
    // setCitations([]);
    setSemanticLoading(true);
    const info = {
      semSearchCitations: true,
      paperId: id,
      pageNo: citePageNo,
    };
    const newData = await sematicCitation(info);
    // console.dir(newData);
    const citationArr = newData.data.data.responseArr;
    if (newData.data.data.response === "") {
      // Toast("error", "No Data Found");
      setSemanticLoading(false);
      setCiteLoadMore(false);
      setHasCitation(false);

      console.log(citationArr);
      if (item.citationCount > 0) {
        setCitationsNoLongerAvailable(true);
      }
    } else {
      setCitations((prev) => {
        const newArray = [...new Set([...prev, ...citationArr])];
        return newArray;
      });
      setCitationsNoLongerAvailable(false);
    }
    setSemanticLoading(false);
    setCiteLoadMore(false);
  };

  const CitehandleLoadMore = () => {
    setCitePageNo(citePageNo + 1);
  };

  useEffect(() => {
    if (citePageNo > 1) {
      setCiteLoadMore(true);
      semanticCall(citePageNo);
    }
  }, [citePageNo]);

  const refCall = async () => {
    setRefLoading(true);
    // setReferences([]);
    const info = {
      semSearchReferences: true,
      paperId: id,
      pageNo: refPageNo,
    };
    const newData = await referenceCitation(info);
    const refArr = newData.data.data.responseArr;

    if (newData.data.data.response === "") {
      // setReferences([]);
      setRefLoading(false);
      setRefLoadMore(false);
      setHasReference(false);
      if (item.referenceCount > 0) {
        setReferencesNoLongerAvailable(true);
      }
    } else {
      setReferences((prevReferences) => {
        const newArray = [...new Set([...prevReferences, ...refArr])];
        return newArray;
      });
      setReferencesNoLongerAvailable(false);
    }
    setRefLoading(false);
    setRefLoadMore(false);
  };
  const RefhandleLoadMore = async () => {
    setRefPageNo(refPageNo + 1);
  };
  useEffect(() => {
    if (refPageNo > 1) {
      setRefLoadMore(true);
      refCall(refPageNo);
    }
  }, [refPageNo]);

  useEffect(() => {
    semanticCall();
    refCall();
  }, [id]);

  // const savePaper = async (item) => {
  //   if (saveLoading && selectedDoi[item.doi]) {
  //     return;
  //   }
  //   setSaveLoading(true);
  //   setSelectedDoi((prev) => ({ ...prev, [item.doi]: item.doi }));
  //   let info = {
  //     email: localStorage.getItem("email"),
  //     title: localStorage.getItem("projectTitle"),
  //     paper: {
  //       title: item.title,
  //       author: item.author,
  //       year: item.year,
  //       url: item.url,
  //       abstracts: item.abstracts,
  //       journal: item.journal,
  //       doi: item.doi,
  //       uploadDate: Date.now() / 1000,
  //     },
  //   };
  //   let res = await saveAbstracts(info);
  //   //for saving references
  //   // let req = {
  //   //   researchProjectId: localStorage.getItem("projectId"),
  //   //   doi: item.doi,
  //   // };
  //   let req = {
  //     saveReference: true,
  //     researchProjectId: localStorage.getItem("projectId"),
  //     title: item.title,
  //     doi: item.doi,
  //   };
  //   // let references = req.references || {};
  //   // references[item.citation] = item?.citation;
  //   // req.references = references;
  //   // await saveResearchSearchReference(req);
  //   await saveReferenceNew(req);
  //   dispatch(clearRefs());
  //   setSaveLoading(false);
  //   let copy = JSON.parse(JSON.stringify(selectedDoi));
  //   delete copy[item.doi];
  //   setSelectedDoi(copy);
  //   if (res?.abstractSaved) {
  //     Toast("success", "Reference added & abstract saved in your library.");
  //     // setSaveStatus((prev) => ({ ...prev, [item.doi]: true }));
  //     dispatch(addSemanticStatus({ ...semanticSaveStatus, [item.doi]: true }));
  //   }
  // };

  const savePaper = async (item) => {
    // if (saveLoading && selectedDoi[item.doi]) {
    //   return;
    // }
    // setSaveLoading(true);
    // setSelectedDoi((prev) => ({ ...prev, [item.doi]: item.doi }));

    // const key = item.doi || item.title;
    const key = item.doi && item.doi !== "None" ? item.doi : item.title;

    if (saveLoading[key]) {
      return;
    }
    setSaveLoading((prev) => ({ ...prev, [key]: true }));
    setSelectedDoi((prev) => ({ ...prev, [key]: true }));

    let req = {
      saveReference: true,
      researchProjectId: localStorage.getItem("projectId"),
      title: item.title,
      doi: item.doi,
    };
    const referenceRes = await saveReferenceNew(req);
    console.log("referenceRes:", referenceRes);
    if (referenceRes?.data?.data?.sourceObj === "") {
      Toast("err", "NO data found");
      return;
    }
    if (referenceRes?.data?.data?.sourceObj) {
      // let refExt = referenceRes?.data?.sourceObj?.reference;
      // if (!refExt) {
      //   console.error("Reference not found in the response");
      //   return;
      // }
      console.log("hellll");
      let absInfo = {
        email: localStorage.getItem("email"),
        title: localStorage.getItem("projectTitle"),
        paper: {
          title: item.title,
          author: item.author,
          year: item.year,
          url: item.url,
          abstracts: item.abstracts,
          journal: item.journal,
          doi: item.doi,
          uploadDate: Date.now() / 1000,
        },
        reference: referenceRes?.data?.data?.sourceObj?.reference,
      };
      let res = await saveAbstracts(absInfo);
      dispatch(getRefs(localStorage.getItem("projectId")));

      //for saving references
      // let req = {
      //   researchProjectId: localStorage.getItem("projectId"),
      //   doi: item.doi,
      // };

      // let references = req.references || {};
      // references[item.citation] = item?.citation;
      // req.references = references;
      // await saveResearchSearchReference(req);

      dispatch(clearRefs());
      // setSaveLoading(false);
      // let copy = JSON.parse(JSON.stringify(selectedDoi));
      // // delete copy[item.doi];
      // delete copy[key];
      // setSelectedDoi(copy);

      setSaveLoading((prev) => ({ ...prev, [key]: false }));
      setSelectedDoi((prev) => {
        let copy = { ...prev };
        delete copy[key];
        return copy;
      });

      if (res?.abstractSaved) {
        Toast("success", "Reference added & abstract saved in your library.");
        // setSaveStatus((prev) => ({ ...prev, [item.doi]: true }));
        // dispatch(addSemanticStatus({ ...semanticSaveStatus, [item.doi]: true }));
        dispatch(addSemanticStatus({ ...semanticSaveStatus, [key]: true }));
      }
    }
  };

  const goBack = () => {
    navigate(`/projects/${localStorage.getItem("projectTitle")}/search-papers`);
    localStorage.setItem("navigationHistory", JSON.stringify([]));
    localStorage.setItem("selectedItem", JSON.stringify([]));
  };

  const updateSelectedItem = (newItem) => {
    console.log("newItem:", newItem);

    // Get the current navigation history from local storage
    const navigationHistory =
      JSON.parse(localStorage.getItem("navigationHistory")) || [];

    // Add the current item to the history
    if (item) {
      navigationHistory.push(item);
      localStorage.setItem(
        "navigationHistory",
        JSON.stringify(navigationHistory)
      );
    }
    setCitePageNo(1);
    setCitations([]);
    // setCiteLoadMore(true);
    setHasCitation(true);

    setRefPageNo(1);
    setReferences([]);
    // setRefLoadMore(true);
    setHasReference(true);

    // Store the new item as the selected item
    localStorage.setItem("selectedItem", JSON.stringify(newItem));
    navigate(
      `/projects/${localStorage.getItem("projectTitle")}/search-papers/${
        newItem.paperId
      }`
    );
    setItem(newItem);
    dispatch(addItems(newItem));
  };

  const goBackToPreviousItem = () => {
    const navigationHistory =
      JSON.parse(localStorage.getItem("navigationHistory")) || [];

    if (navigationHistory.length > 0) {
      // Get the last item in the history
      const previousItem = navigationHistory.pop();
      localStorage.setItem(
        "navigationHistory",
        JSON.stringify(navigationHistory)
      );

      // Set the previous item as the current item
      setItem(previousItem);
      dispatch(addItems(previousItem));
      navigate(
        `/projects/${localStorage.getItem("projectTitle")}/search-papers/${
          previousItem.paperId
        }`
      );
      setCitePageNo(1);
      setCitations([]);
      // setCiteLoadMore(true);
      setHasCitation(true);

      setRefPageNo(1);
      setReferences([]);
      // setRefLoadMore(true);
      setHasReference(true);
    }
  };

  const hasNavigationHistory = () => {
    const navigationHistory =
      JSON.parse(localStorage.getItem("navigationHistory")) || [];
    return navigationHistory.length > 0;
  };

  if (!item) {
    return <div>No item data available. Item ID: {id}</div>;
  }

  return (
    // <div>
    //   <h1>{item.title}</h1>
    //   <p>{item.abstracts}</p>
    // </div>
    <Box
      sx={{ py: "16px", px: { xs: 1, md: 4 }, height: "100vh" }}
      id="search-paper"
    >
      <SemanticContent
        item={item}
        isSmall={isSmall}
        goBack={goBack}
        goBackToPreviousItem={goBackToPreviousItem}
        saveLoading={saveLoading}
        selectedDoi={selectedDoi}
        semanticSaveStatus={semanticSaveStatus}
        savePaper={savePaper}
        tooltipOpen={tooltipOpen}
        handleCopy={handleCopy}
        hasNavigationHistory={hasNavigationHistory}
      />

      <Box sx={{ width: "100%" }}>
        <Box sx={{ bgcolor: "#fff" }}>
          <AntTabs
            value={value}
            onChange={handleChange}
            aria-label="ant example"
          >
            <AntTab label="Citations" {...a11yProps(0)} />
            <AntTab label="References" {...a11yProps(1)} />
          </AntTabs>

          {/* i am citation */}
          <CustomTabPanel value={value} index={0}>
            <Box sx={{ py: 1 }}>
              {semanticLoading && !citeLoadMore ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: isSmall ? "10vh" : "20vh",
                  }}
                >
                  <TailSpin
                    height="40px"
                    width="40px"
                    color="#5AB7BF"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                  <Typography>Fetching Data...</Typography>
                </Box>
              ) : citation?.length > 0 ? (
                <>
                  {citation?.map((item) => (
                    <SemanticItemBox
                      key={item.id}
                      item={item}
                      handleItemClick={() => {
                        updateSelectedItem(item);
                        const element = document.getElementById("search-paper");
                        if (element) {
                          element.scrollIntoView({ behavior: "smooth" });
                        }
                        setCitations([]);
                      }}
                      truncateText={truncateText}
                      savePaper={savePaper}
                      saveLoading={saveLoading}
                      selectedDoi={selectedDoi}
                      semanticSaveStatus={semanticSaveStatus}
                    />
                  ))}
                </>
              ) : citationsNoLongerAvailable ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: isSmall ? "10vh" : "20vh",
                  }}
                >
                  <Title
                    sx={{
                      fontSize: "16px",
                      fontWeight: 500,
                      color: "#4E4E4E",
                      my: 1,
                    }}
                  >
                    Cited Papers not available
                  </Title>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: isSmall ? "10vh" : "20vh",
                  }}
                >
                  <Title
                    sx={{
                      fontSize: "16px",
                      fontWeight: 500,
                      color: "#4E4E4E",
                      my: 1,
                    }}
                  >
                    No Citations To Show
                  </Title>
                </Box>
              )}

              {citation?.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    my: 2,
                  }}
                >
                  {hasCitation ? (
                    <RecomendationBtn
                      sx={{
                        // background:
                        //   "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
                        // "&:hover": {
                        //   background:
                        //     "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%)",
                        // },
                        background: "#1E4F6B",
                        "&:hover": {
                          background: "#1E4F6B",
                        },
                        color: "#fff",
                      }}
                      onClick={CitehandleLoadMore}
                    >
                      Load More...{" "}
                      {citeLoadMore && (
                        <TailSpin
                          height="20px"
                          width="20px"
                          color="#fff"
                          ariaLabel="tail-spin-loading"
                          radius="1"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      )}
                    </RecomendationBtn>
                  ) : (
                    <Box
                      sx={{
                        color: "#4E4E4E",
                        padding: "10px",
                        borderRadius: "4px",
                        textAlign: "center",
                      }}
                    >
                      No more citations
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            <Box sx={{ py: 1 }}>
              {refLoading && !refLoadMore ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: isSmall ? "10vh" : "20vh",
                  }}
                >
                  <TailSpin
                    height="40px"
                    width="40px"
                    color="#5AB7BF"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                  <Typography>Fetching Data...</Typography>
                </Box>
              ) : references.length > 0 ? (
                <>
                  {references?.map((item) => (
                    <SemanticItemBox
                      key={item.id}
                      item={item}
                      handleItemClick={() => {
                        updateSelectedItem(item);
                        const element = document.getElementById("search-paper");
                        if (element) {
                          element.scrollIntoView({ behavior: "smooth" });
                        }
                        setReferences([]);
                      }}
                      truncateText={truncateText}
                      savePaper={savePaper}
                      saveLoading={saveLoading}
                      selectedDoi={selectedDoi}
                      semanticSaveStatus={semanticSaveStatus}
                    />
                  ))}
                </>
              ) : referencesNoLongerAvailable ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: isSmall ? "10vh" : "20vh",
                  }}
                >
                  <Title
                    sx={{
                      fontSize: "16px",
                      fontWeight: 500,
                      color: "#4E4E4E",
                      my: 1,
                    }}
                  >
                    Reference Papers not available
                  </Title>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: isSmall ? "10vh" : "20vh",
                  }}
                >
                  <Title
                    sx={{
                      fontSize: "16px",
                      fontWeight: 500,
                      color: "#4E4E4E",
                      my: 1,
                    }}
                  >
                    No References To Show
                  </Title>
                </Box>
              )}
              {references?.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    my: 2,
                  }}
                >
                  {hasReference ? (
                    <RecomendationBtn
                      sx={{
                        // background:
                        //   "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
                        // "&:hover": {
                        //   background:
                        //     "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%)",
                        // },
                        background: "#1E4F6B",
                        "&:hover": {
                          background: "#1E4F6B",
                        },
                        color: "#fff",
                      }}
                      onClick={RefhandleLoadMore}
                    >
                      Load More ...{" "}
                      {refLoadMore && (
                        <TailSpin
                          height="20px"
                          width="20px"
                          color="#fff"
                          ariaLabel="tail-spin-loading"
                          radius="1"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      )}
                    </RecomendationBtn>
                  ) : (
                    <Box
                      sx={{
                        color: "#4E4E4E",
                        padding: "10px",
                        borderRadius: "4px",
                        textAlign: "center",
                      }}
                    >
                      No more references
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </CustomTabPanel>
        </Box>
      </Box>
    </Box>
  );
};

export default SemanticItems;
