import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import misteral from "../../../assets/images/misteral.png";
import stability from "../../../assets/images/NewImages/stability.png";
import anthro from "../../../assets/images/anthro.png";
import openai from "../../../assets/images/openai.png";

import meta from "../../../assets/images/meta.png";
import google from "../../../assets/images/google.png";
import midJourney from "../../../assets/images/midJourney.png";
import mixtral from "../../../assets/images/mixtral.png";
import openai1 from "../../../assets/images/openai1.png";
import stableDiffussion from "../../../assets/images/stableDiffussion.png";

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "Raleway",
  fontWeight: 600,
  fontSize: "32px",
  color: "#fff",
  textAlign: "center",
  marginBottom: "10px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "25px",
  },
}));
const Text = styled(Typography)(({ theme }) => ({
  backgroundColor: "#f3f3f3",
  borderRadius: "10px",
  fontSize: "14px",
  fontFamily: "Raleway",
  textAlign: "center",
  height: "50px",
  paddingInline: "5px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: 600,
}));

const ModalsSection = () => {
  const isSmallScreen = useMediaQuery("(max-width:900px)");
  const CustomGrid = styled(Grid)({
    display: "flex",
    justifyContent: "center",

    // border: isSmallScreen && "1px solid #f8f8f8",
    margin: isSmallScreen && "2px",
  });
  return (
    <Box
      sx={{
        marginTop: "100px",
        background: "linear-gradient(90deg, #5671F1, #17CEAD)",
        borderRadius: "10px",
        padding: "20px",
      }}
    >
      {" "}
      <Title>Access All The Best & Latest AI Models</Title>
      <Typography textAlign="center" fontFamily="Raleway" color="#fff">
        Your students and faculty have access to all the best and latest AI
        models in one place:
      </Typography>
      <Grid container spacing={2} mt={4}>
        <CustomGrid
          item
          xs={12}
          md={3}
          sx={{
            width: "70px !important",
            height: "70px !important",
          }}
        >
          <img src={misteral} alt="" />
        </CustomGrid>
        <CustomGrid
          item
          xs={12}
          md={3}
          // sx={{
          //   width: "120px  ",
          //   height: "120px  ",
          // }}
        >
          <img
            src={stability}
            alt=""
            style={{
              width: "80px",
              height: "80px",
            }}
          />
        </CustomGrid>
        <CustomGrid
          item
          xs={12}
          md={3}
          sx={{
            width: "70px !important",
            height: "70px !important",
          }}
        >
          <img src={anthro} alt="" />
        </CustomGrid>
        <CustomGrid
          item
          xs={12}
          md={3}
          sx={{
            width: "70px !important",
            height: "70px !important",
          }}
        >
          <img src={openai} alt="" />
        </CustomGrid>
      </Grid>
      <Grid container mt={4} spacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "center",
            width: "100%",
          }}
        >
          {" "}
          <Card
            sx={{
              maxWidth: "100%",
              borderRadius: "20px",
              boxShadow: "0px 0px 4px rgba(0.25, 0.25, 0, 0.25)",
              background: "linear-gradient(90deg, #E6D7AF, #96D2D5)",
            }}
          >
            <CardHeader
              title="Open Source Models"
              sx={{
                "&.MuiCardHeader-root": {
                  backgroundColor: "#1E4F6B",
                  borderBottomRightRadius: isSmallScreen ? "0px" : "20px",
                  width: isSmallScreen ? "100%" : "50%",
                  color: "#fff",
                  fontSize: "10px !important",
                },
                "& .MuiCardHeader-content .MuiCardHeader-title": {
                  fontSize: "16px",
                  fontFamily: "Raleway",
                },
              }}
            />

            <CardContent
              sx={{
                background: "linear-gradient(90deg, #E6D7AF, #96D2D5)",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} mb={isSmallScreen ? 4 : 0}>
                  <Stack spacing={5} justifyContent={"space-between"}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={meta} alt="" width={122} height={24} />
                    </Box>
                    <Text>Lama</Text>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={4} mb={isSmallScreen ? 4 : 0}>
                  <Stack spacing={3} justifyContent={"space-between"}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={mixtral} alt="" width={44} height={24} />
                    </Box>
                    <Text>Mixtral 8x 22b</Text>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Stack spacing={3} justifyContent={"space-between"}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={stableDiffussion}
                        alt=""
                        width={40}
                        height={24}
                      />
                    </Box>
                    <Text>Stable Diffusion</Text>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          mt={isSmallScreen ? 4 : 0}
          md={6}
          // sx={{
          //   display: "flex",
          //   alignItems: "center",
          //   justifyContent: "center",
          // }}
        >
          {" "}
          <Card
            sx={{
              maxWidth: "100%",
              borderRadius: "20px",
              boxShadow: "0px 0px 4px rgba(0.25, 0.25, 0, 0.25)",
              background: "linear-gradient(90deg, #E6D7AF, #96D2D5)",
            }}
          >
            <CardHeader
              title="Open Source Models"
              sx={{
                "&.MuiCardHeader-root": {
                  backgroundColor: "#1E4F6B",
                  borderBottomRightRadius: isSmallScreen ? "0px" : "20px",
                  width: isSmallScreen ? "100%" : "50%",
                  color: "#fff",
                  fontSize: "10px !important",
                },
                "& .MuiCardHeader-content .MuiCardHeader-title": {
                  fontSize: "16px",
                  fontFamily: "Raleway",
                },
              }}
            />

            <CardContent
              sx={{
                background: "linear-gradient(90deg, #E6D7AF, #96D2D5)",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={3} mb={isSmallScreen ? 4 : 0}>
                  <Stack
                    spacing={3}
                    justifyContent={"space-between"}
                    // sx={{
                    //   height: "150px",
                    // }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={openai1} alt="" width={40} height={24} />
                    </Box>
                    <Text>DALL.E</Text>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={3} mb={isSmallScreen ? 4 : 0}>
                  <Stack spacing={3} justifyContent={"space-between"}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={midJourney} alt="" width={47} height={24} />
                    </Box>
                    <Text>Midjourney</Text>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={3} mb={isSmallScreen ? 4 : 0}>
                  <Stack spacing={3} justifyContent={"space-between"}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={google} alt="" width={40} height={24} />
                    </Box>
                    <Text>Claude Opus</Text>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Stack spacing={3} justifyContent={"space-between"}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={openai1} alt="" width={40} height={24} />
                    </Box>
                    <Text>GPT 4 Turbo</Text>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ModalsSection;
